import { uStaffId, uToken } from ".";
import { ApiUrl, HostUrl } from "./config";
import Swal from 'sweetalert2';



export const sendRequest = async (slug, method = 'GET', data = null) => {
    const options = {
        method,
        headers: {
            // "Accept": "application/json",
            // "Content-Type": "application/json",
            // 'Content-Type': 'application/x-www-form-urlencoded',

        },
    };

    if (uToken()) {
        options.headers.Authorization = `Bearer ` + uToken();
    }

    options.headers['Staff-User-Id'] = uStaffId();

    if (method !== 'GET') {
        options.body = data instanceof FormData ? data : new URLSearchParams(data).toString();
    }

    try {
        const response = await fetch(ApiUrl + slug, options);
        const responseData = await response.json();

        if(responseData.status === false && responseData.message === 'Unauthorized: Please log in again. Reload the page or logout for security reason'){
            Swal.fire({
                title: responseData.message,
                icon: 'error',
            }).then(function () {
                window.location.href = '/auths/auth-login';
            });
        }

        if (!response.ok) {
            throw new Error(responseData.message || 'Request failed');
        }

        if (responseData.status === false) {
            Swal.fire({
                title: responseData.message,
                icon: 'error',
            });
        }

        return responseData;
    } catch (error) {
        throw error;
    }
};


export const sendRequestKeyPair = async (slug, method = 'GET', data = null) => {
    const options = {
        method,
        headers: {
            "Accept": "application/json",
            "Content-Type": "application/json",
        },
    };

    if (method !== 'GET') {
        options.body = data ? JSON.stringify(data) : null;
    }

    try {
        const response = await fetch(ApiUrl + slug, options);
        const responseData = await response.json();

        if (!response.ok) {
            throw new Error(responseData.message || 'Request failed');
        }

        return responseData;
    } catch (error) {
        throw error;
    }
};