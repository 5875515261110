import { useState, useEffect } from "react";
import { Link, useParams } from 'react-router-dom';
import { Card, Button, Row, Col, Form, Spinner } from 'react-bootstrap';

import Layout from '../../layout/default';
import Block from '../../components/Block/Block';
import { Image, Icon, Media, Select, TimeZone } from '../../components';
import { toInitials } from "../../utilities";
import { sendRequest } from "../../utilities/api";
import { countries } from "../../store/customData";

function LocationCreate(props) {
    const [meta, setMeta] = useState({ coachPartners: [], calendars: [] });
    const [activeStep, setActiveStep] = useState(0);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        fetchMeta();
    }, []);

    const fetchMeta = () => {
        const formData = new FormData();
        sendRequest('location/meta/fetch', 'POST', formData).then(response => {
            if (response.status === true) {
                setMeta(response.data);
                setLoading(true);
            }
        })
            .catch(error => {
                setLoading(true);
                console.error('POST request error:', error);
            });
    }


    const handleSubmit = async (event) => {
        event.preventDefault();
        const formData = new FormData(event.currentTarget);
        if (activeStep == 0) {
            setActiveStep(1);
        }
        else if (activeStep == 1) {
            setActiveStep(2)
        } else if (activeStep == 2) {

            const submitData = new FormData();
            for (let [key, value] of formData.entries()) {
                submitData.append(key, value);
            }
            sendRequest('location/create', 'POST', submitData)
                .then(response => {
                    if (response.status === true) {
                        props.navigate('/location-manage/lists');
                    }
                })
                .catch(error => {
                    console.error('POST request error:', error);
                });
        }
    };


    const steps = ['Location Setup', 'Additional Info & Tax', 'Add Team'];
    return (
        <Layout title="Location" content="container-fluid">
            <div className="location-create">
                <Block.Head>
                    <Block.HeadContent>
                        <Block.Title tag="h2">Location</Block.Title>
                        <nav>
                            <ol className="breadcrumb breadcrumb-arrow mb-0">
                                <li className="breadcrumb-item"><Link to="/">Home</Link></li>
                                <li className="breadcrumb-item"><Link to="/location-manage/lists">Location</Link></li>
                                <li className="breadcrumb-item active" aria-current="page">Create</li>
                            </ol>
                        </nav>
                    </Block.HeadContent>
                </Block.Head>
                <Block>
                    {
                        !loading ? (
                            <Spinner animation="grow" variant="primary"></Spinner>
                        ) : (
                            <Card className="card-gutter-md">

                                <Card.Body>
                                    <div className="bio-block">
                                        <Form onSubmit={handleSubmit}>

                                            <Row className="g-3 mb-3">
                                                {steps.map((step, index) => (
                                                    <div
                                                        key={step}
                                                        className={`step ${activeStep === index ? 'active' : ''}`}
                                                    >
                                                        <span className="step-count">{index + 1}</span>
                                                        <p className="step-name">{step}</p>
                                                    </div>
                                                ))}
                                            </Row>


                                            <div className={`bio-block ${activeStep == 0 ? '' : 'd-none'} `}>
                                                <Row className="g-3">
                                                    <Col md="6">
                                                        <Form.Group className="form-group">
                                                            <Form.Label htmlFor="name">Name</Form.Label>
                                                            <div className="form-control-wrap">
                                                                <Form.Control type="text" name="name" required={activeStep === 0} />
                                                            </div>
                                                        </Form.Group>
                                                    </Col>
                                                    <Col md="6">
                                                        <Form.Group className="form-group">
                                                            <Form.Label htmlFor="company">Company Name</Form.Label>
                                                            <div className="form-control-wrap">
                                                                <Form.Control type="text" name="company" required={activeStep === 0} />
                                                            </div>
                                                        </Form.Group>
                                                    </Col>
                                                    <Col md="6">
                                                        <Form.Group className="form-group">
                                                            <Form.Label htmlFor="short_name">Short Name</Form.Label>
                                                            <div className="form-control-wrap">
                                                                <Form.Control type="text" name="short_name" required={activeStep === 0} />
                                                            </div>
                                                        </Form.Group>
                                                    </Col>
                                                    <Col md="6">
                                                        <Form.Group className="form-group">
                                                            <Form.Label htmlFor="email">Email</Form.Label>
                                                            <div className="form-control-wrap">
                                                                <Form.Control type="text" name="email" required={activeStep === 0} />
                                                            </div>
                                                        </Form.Group>
                                                    </Col>

                                                    <Col md="6">
                                                        <Form.Group className="form-group">
                                                            <Form.Label htmlFor="country_code">Country Code</Form.Label>
                                                            <div className="form-control-wrap">
                                                                <Form.Select name="country_code">
                                                                    <option value="">Select Country</option>
                                                                    {
                                                                        countries.map((row, i) => {
                                                                            return <option key={i} value={row.code}>{row.label}</option>
                                                                        })
                                                                    }
                                                                </Form.Select>
                                                            </div>
                                                        </Form.Group>
                                                    </Col>
                                                    <Col md="6">
                                                        <Form.Group className="form-group">
                                                            <Form.Label htmlFor="phone">Phone</Form.Label>
                                                            <div className="form-control-wrap">
                                                                <Form.Control type="number" name="phone" required={activeStep === 0} />
                                                            </div>
                                                        </Form.Group>
                                                    </Col>


                                                    <Col md="6">
                                                        <Form.Group className="form-group">
                                                            <Form.Label htmlFor="City">City</Form.Label>
                                                            <div className="form-control-wrap">
                                                                <Form.Control type="City" name="city" />
                                                            </div>
                                                        </Form.Group>
                                                    </Col>

                                                    <Col md="6">
                                                        <Form.Group className="form-group">
                                                            <Form.Label htmlFor="State">State</Form.Label>
                                                            <div className="form-control-wrap">
                                                                <Form.Control type="State" name="state" />
                                                            </div>
                                                        </Form.Group>
                                                    </Col>

                                                    <Col md="6">
                                                        <Form.Group className="form-group">
                                                            <Form.Label htmlFor="Zip">Zip</Form.Label>
                                                            <div className="form-control-wrap">
                                                                <Form.Control type="Zip" name="zip" />
                                                            </div>
                                                        </Form.Group>
                                                    </Col>
                                                    <Col md="12">
                                                        <Form.Group className="form-group">
                                                            <Form.Label htmlFor="address">Address</Form.Label>
                                                            <div className="form-control-wrap">
                                                                <Form.Control type="text" name="address" required={activeStep === 0} />
                                                            </div>
                                                        </Form.Group>
                                                    </Col>


                                                    <Col className="col-12 text-end">
                                                        <Button type="submit" className="btn-sm">Next</Button>
                                                    </Col>



                                                </Row>
                                            </div>



                                            <div className={`bio-block ${activeStep == 1 ? '' : 'd-none'} `}>
                                                <Row className="g-3">

                                                    <Col md="6">
                                                        <Form.Group className="form-group">
                                                            <Form.Label htmlFor="tax_1">Tax 1</Form.Label>
                                                            <div className="form-control-wrap">
                                                                <Form.Control type="text" name="tax_1" required={activeStep === 1} />
                                                            </div>
                                                        </Form.Group>
                                                    </Col>
                                                    <Col md="6">
                                                        <Form.Group className="form-group">
                                                            <Form.Label htmlFor="tax_1">Tax2</Form.Label>
                                                            <div className="form-control-wrap">
                                                                <Form.Control type="text" name="tax_2" required={activeStep === 1} />
                                                            </div>
                                                        </Form.Group>
                                                    </Col>
                                                    <Col md="6">
                                                        <Form.Group className="form-group">
                                                            <Form.Label htmlFor="logo">Logo</Form.Label>
                                                            <div className="form-control-wrap">
                                                                <Form.Control type="file" name="logo" />
                                                            </div>
                                                        </Form.Group>
                                                    </Col>
                                                    <Col md="6">
                                                        <Form.Group className="form-group">
                                                            <Form.Label htmlFor="footer_text">Footet text</Form.Label>
                                                            <div className="form-control-wrap">
                                                                <Form.Control type="text" name="footer_text" required={activeStep === 1} />
                                                            </div>
                                                        </Form.Group>
                                                    </Col>
                                                    <Col md="12">
                                                        <TimeZone main_key="main_zone" sub_key="time_zone" mainZone='' zone='' />
                                                    </Col>
                                                    <Col className="col-12 text-end">
                                                        <Button className="btn-sm mr-5" type="button" onClick={() => setActiveStep(activeStep - 1)}>Prev</Button>
                                                        <Button type="submit" className="btn-sm">Next</Button>
                                                    </Col>
                                                </Row>
                                            </div>



                                            <div className={`bio-block ${activeStep == 2 ? '' : 'd-none'} `}>
                                                <Row className="g-3">
                                                    <Col md="6">
                                                        <Form.Group className="form-group">
                                                            <Form.Label htmlFor="role">Assign To</Form.Label>
                                                            {meta?.coachPartners.length > 0 ? (
                                                                <Select name="permissions[]" multiple removeItemButton>
                                                                    <option value="">Select</option>
                                                                    {
                                                                        meta.coachPartners.map((row, i) => {
                                                                            return <option key={i} value={row.id}>{row.first_name} {row.last_name}</option>
                                                                        })
                                                                    }
                                                                </Select>
                                                            ) :
                                                                <Select name="permissions[]" multiple removeItemButton>
                                                                    <option value="">Select</option>
                                                                </Select>

                                                            }
                                                        </Form.Group>
                                                    </Col>
                                                    <Col md="6">
                                                        <Form.Group className="form-group">
                                                            <Form.Label htmlFor="role">Select Calendar</Form.Label>
                                                            {meta?.calendars.length > 0 ? (
                                                                <Select name="calendar_id[]" multiple removeItemButton>
                                                                    <option value="">Select</option>
                                                                    {
                                                                        meta.calendars.map((row, i) => {
                                                                            return <option key={i} value={row.id}>{row.title}</option>
                                                                        })
                                                                    }
                                                                </Select>
                                                            ) : (
                                                                <Select name="calendar_id[]" multiple removeItemButton>
                                                                    <option value="">Select</option>
                                                                </Select>
                                                            )}
                                                        </Form.Group>
                                                    </Col>
                                                    <Col className="col-12 text-end">
                                                        <Button className="btn-sm mr-5" type="button" onClick={() => setActiveStep(activeStep - 1)}>Prev</Button>
                                                        <Button type="submit" className="btn-sm">Create</Button>
                                                    </Col>
                                                </Row>
                                            </div>


                                        </Form>
                                    </div>
                                </Card.Body>
                            </Card>
                        )
                    }
                </Block>
            </div>
        </Layout>
    )
}

export default LocationCreate;