import React, { useEffect } from 'react'
import classNames from 'classnames'

// import getParents from '../../../utilities/getParents';
import slideUp from '../../../utilities/slideUp';
import slideDown from '../../../utilities/slideDown';
import getParents from '../../../utilities/getParents';

import { NavLink, Link } from "react-router-dom";
import { useAuth } from '../../../AuthContext';
import { uInfo, uPublicId } from '../../../utilities';

function MenuHeading({ className, text, ...props }) {
    const compClass = classNames({
        'nk-menu-heading': true,
        [className]: className
    });
    return (
        <li className={compClass}>
            <h6 className="overline-title">{text || props.children}</h6>
        </li>
    )
}

function MenuItemTemplate({ text, icon }) {
    return (
        <>
            {icon && <span className="nk-menu-icon"><em className={`icon ni ni-${icon}`}></em></span>}
            {text && <span className="nk-menu-text">{text}</span>}
        </>
    )
}

function MenuItemLink({ text, icon, sub, to, blank, onClick }) {
    return (
        <>
            {(!blank && !sub) && <NavLink className="nk-menu-link" to={to}><MenuItemTemplate icon={icon} text={text} /></NavLink>}
            {blank && <Link className="nk-menu-link" to={to} target="_blank"><MenuItemTemplate icon={icon} text={text} /></Link>}
            {sub && <a className="nk-menu-link nk-menu-toggle" onClick={onClick} href="#expand"><MenuItemTemplate icon={icon} text={text} /></a>}
        </>
    )
}

function MenuItem({ sub, className, ...props }) {
    const compClass = classNames({
        'nk-menu-item': true,
        'has-sub': sub,
        [className]: className
    });
    return (
        <li className={compClass}>
            {props.children}
        </li>
    )
}

function MenuSub({ mega, className, ...props }) {
    const compClass = classNames({
        'nk-menu-sub': true,
        [className]: className
    });
    return (
        <ul className={compClass}>
            {props.children}
        </ul>
    )
}

function MenuList({ className, ...props }) {
    const compClass = classNames({
        'nk-menu': true,
        [className]: className
    });
    return (
        <ul className={compClass}>
            {props.children}
        </ul>
    )
}

function Menu() {

    const { hasPermission } = useAuth();

    // variables for Sidebar
    let menu = {
        classes: {
            main: 'nk-menu',
            item: 'nk-menu-item',
            link: 'nk-menu-link',
            toggle: 'nk-menu-toggle',
            sub: 'nk-menu-sub',
            subparent: 'has-sub',
            active: 'active',
            current: 'current-page'
        },
    };

    let currentLink = function (selector) {
        let elm = document.querySelectorAll(selector);
        elm.forEach(function (item) {
            var activeRouterLink = item.classList.contains('active');
            if (activeRouterLink) {
                let parents = getParents(item, `.${menu.classes.main}`, menu.classes.item);
                parents.forEach(parentElemets => {
                    parentElemets.classList.add(menu.classes.active, menu.classes.current);
                    let subItem = parentElemets.querySelector(`.${menu.classes.sub}`);
                    subItem !== null && (subItem.style.display = "block")
                })

            } else {
                item.parentElement.classList.remove(menu.classes.active, menu.classes.current);
            }
        })
    }


    // dropdown toggle
    let dropdownToggle = function (elm) {
        let parent = elm.parentElement;
        let nextelm = elm.nextElementSibling;
        let speed = nextelm.children.length > 5 ? 400 + nextelm.children.length * 10 : 400;
        if (!parent.classList.contains(menu.classes.active)) {
            parent.classList.add(menu.classes.active);
            slideDown(nextelm, speed);
        } else {
            parent.classList.remove(menu.classes.active);
            slideUp(nextelm, speed);
        }
    }

    // dropdown close siblings
    let closeSiblings = function (elm) {
        let parent = elm.parentElement;
        let siblings = parent.parentElement.children;
        Array.from(siblings).forEach(item => {
            if (item !== parent) {
                item.classList.remove(menu.classes.active);
                if (item.classList.contains(menu.classes.subparent)) {
                    let subitem = item.querySelectorAll(`.${menu.classes.sub}`);
                    subitem.forEach(child => {
                        child.parentElement.classList.remove(menu.classes.active);
                        slideUp(child, 400);
                    })
                }
            }
        });
    }

    let menuToggle = function (e) {
        e.preventDefault();
        let item = e.target.closest(`.${menu.classes.toggle}`)
        dropdownToggle(item);
        closeSiblings(item);
    }

    useEffect(() => {
        currentLink(`.${menu.classes.link}`);
        // eslint-disable-next-line
    }, [null])

    return (

        <MenuList>
            <MenuItem>
                <MenuItemLink icon="dashboard" text="Dashboard" to="/home" />
            </MenuItem>

            {/* <MenuItem>
                <MenuItemLink icon="file-text" text="Blogs" to="/blogs" />
            </MenuItem> */}
            {/* <MenuHeading text="Applications" /> */}

            {
                uInfo().type == 'Reseller' && (
                    <>
                        {
                            (hasPermission('users') || hasPermission('viewSubManager') || hasPermission('viewManager') || hasPermission('viewStaff')) && (
                                <MenuItem sub>


                                    <MenuItemLink icon="users" text="Users" onClick={menuToggle} sub />
                                    <MenuSub>
                                        {
                                            hasPermission('reseller') && (
                                                <MenuItem>
                                                    <MenuItemLink icon="users" text="Reseller" to="/user-manage/reseller/lists" />
                                                </MenuItem>
                                            )
                                        }

                                        {
                                            hasPermission('viewManager') && (
                                                <MenuItem>
                                                    <MenuItemLink text="Client" to="/user-manage/manager/lists" />
                                                </MenuItem>
                                            )
                                        }

                                        {
                                            hasPermission('viewSubManager') && (
                                                <MenuItem>
                                                    <MenuItemLink text="Team" to="/user-manage/submanager/lists" />
                                                </MenuItem>
                                            )
                                        }

                                        {/* {
                        hasPermission('members') && (
                            <MenuItem>
                                <MenuItemLink icon="users" text="Members" to="/user-manage/members/lists" />
                            </MenuItem>
                        )
                    } */}

                                        {
                                            hasPermission('viewStaff') && (
                                                <MenuItem>
                                                    <MenuItemLink text="Staff" to="/user-manage/staff/lists" />
                                                </MenuItem>
                                            )
                                        }

                                    </MenuSub>
                                </MenuItem>
                            )
                        }
                    </>
                )
            }
            {
                hasPermission('packages') && (
                    <MenuItem sub>
                        <MenuItemLink icon="package" text="Packages" onClick={menuToggle} sub />
                        <MenuSub>
                            {
                                hasPermission('viewResellerPackage') && (
                                    <MenuItem>
                                        <MenuItemLink text="Reseller Packages" to="/package-manage/reseller/lists" />
                                    </MenuItem>
                                )
                            }

                            {
                                hasPermission('viewManagerPackage') && (
                                    <MenuItem>
                                        <MenuItemLink text="Client Packages" to="/package-manage/manager/lists" />
                                    </MenuItem>
                                )
                            }

                            {
                                hasPermission('viewCreditPackage') && (
                                    <MenuItem>
                                        <MenuItemLink text="Credit Packages" to="/package-manage/credit/lists" />
                                    </MenuItem>
                                )
                            }
                        </MenuSub>
                    </MenuItem>
                )
            }

            {
                hasPermission('credits') && (

                    <MenuItem sub>
                        <MenuItemLink icon="cc-alt" text="Credits" onClick={menuToggle} sub />
                        <MenuSub>
                            {
                                hasPermission('viewCreditFreeReseller') && (
                                    <MenuItem>
                                        <MenuItemLink text="Reseller Free Credit" to="/credit-manage/reseller" />
                                    </MenuItem>
                                )
                            }

                            {
                                hasPermission('viewCreditFreeManager') && (
                                    <MenuItem>
                                        <MenuItemLink text="Client Free Credit" to="/credit-manage/manager" />
                                    </MenuItem>
                                )
                            }

                            {
                                hasPermission('viewConsumptionCriteria') && (
                                    <MenuItem>
                                        <MenuItemLink text="Consumption Criteria" to="/credit-manage/consumption/criteria" />
                                    </MenuItem>
                                )
                            }

                        </MenuSub>
                    </MenuItem>
                )
            }

            {
                hasPermission('viewLeads') && (
                    <MenuItem>
                        <MenuItemLink icon="users" text="Clients" to="/crm-manage/leads/lists" />
                    </MenuItem>

                    // <MenuItem sub>
                    //     <MenuItemLink icon="users" text="Clients" onClick={menuToggle} sub />
                    //     <MenuSub>
                    //         <MenuItem>
                    //             <MenuItemLink text="Companies" to="/crm-manage/companies-list" />
                    //         </MenuItem>
                    //         {
                    //             hasPermission('groups') && (
                    //                 <MenuItem>
                    //                     <MenuItemLink text="Groups & Tags" to="/group-manage/lists" />
                    //                 </MenuItem>
                    //             )
                    //         }

                    //         <MenuItem>
                    //             <MenuItemLink text="Clients" to="/crm-manage/leads/lists" />
                    //         </MenuItem>
                    //         <MenuItem>
                    //             <MenuItemLink text="Members" to="/user-manage/members/lists" />
                    //         </MenuItem>
                    //         <MenuItem>
                    //             <MenuItemLink text="Contacts" to="/crm-manage/contacts/lists" />
                    //         </MenuItem>
                    //     </MenuSub>
                    // </MenuItem>
                )
            }
            {
                hasPermission('viewJobs') && (
                    <MenuItem>
                        <MenuItemLink icon="briefcase" text="Jobs" to="/jobs/lists" />
                    </MenuItem>
                )
            }
            {
                hasPermission('viewLocation') && (
                    <MenuItem>
                        <MenuItemLink icon="location" text="Location" to="/location-manage/lists" />
                    </MenuItem>
                )
            }



            {
                hasPermission('viewCalendar') && (
                    <MenuItem>
                        <MenuItemLink icon="calendar" text="Calendars" to="/calendar-manage/view/MA==" />
                    </MenuItem>

                    // <MenuItem sub>
                    //     <MenuItemLink icon="calendar" text="Calendar" onClick={menuToggle} sub />
                    //     <MenuSub>
                    //         <MenuItem>
                    //             <MenuItemLink text="View Calendars" to="/calendar-manage/view/MA==" />
                    //         </MenuItem>
                    //         <MenuItem>
                    //             <MenuItemLink text="Manage Calendars" to="/calendar-manage/lists" />
                    //         </MenuItem>
                    //         <MenuItem>
                    //             <MenuItemLink text="View Appointments" to="/calendar-manage/appointments/Lists" />
                    //         </MenuItem>
                    //         <MenuItem>
                    //             <MenuItemLink text="Appointments Status" to="/calendar-manage/appointments/status/Lists" />
                    //         </MenuItem>
                    //     </MenuSub>
                    // </MenuItem>
                )
            }


            {
                hasPermission('cms') && (

                    <MenuItem sub>
                        <MenuItemLink icon="monitor" text="CMS" onClick={menuToggle} sub />
                        <MenuSub>
                            {
                                hasPermission('viewDisclaimer') && (
                                    <MenuItem>
                                        <MenuItemLink text="Disclaimer" to="/cms-manage/disclaimer/lists" />
                                    </MenuItem>
                                )
                            }

                            {
                                hasPermission('viewTerms') && (
                                    <MenuItem>
                                        <MenuItemLink text="Terms Of Service" to="/cms-manage/termsofservice/lists" />
                                    </MenuItem>
                                )
                            }

                            {
                                hasPermission('viewPrivacy') && (
                                    <MenuItem>
                                        <MenuItemLink text="Privacy Policy" to="/cms-manage/privacypolicy/lists" />
                                    </MenuItem>
                                )
                            }

                            {
                                hasPermission('viewNotification') && (
                                    <MenuItem>
                                        <MenuItemLink text="Notification Templates" to="/cms-manage/notification/lists" />
                                    </MenuItem>
                                )
                            }


                        </MenuSub>
                    </MenuItem>

                )
            }

            {
                hasPermission('settings') && (

                    <MenuItem sub>
                        <MenuItemLink icon="setting-alt" text="Setting" onClick={menuToggle} sub />
                        <MenuSub>

                            {
                                hasPermission('viewVoip') && (
                                    <MenuItem>
                                        <MenuItemLink text="Voip Integration" to="/setting-manage/voip/lists" />
                                    </MenuItem>
                                )
                            }

                            {
                                hasPermission('viewEmail') && (
                                    <MenuItem>
                                        <MenuItemLink text="Email Setting" to="/setting-manage/email/lists" />
                                    </MenuItem>
                                )
                            }

                            {
                                hasPermission('viewPayment') && (
                                    <MenuItem>
                                        <MenuItemLink text="Payment Setting" to="/setting-manage/payment/lists" />
                                    </MenuItem>
                                )
                            }

                            {
                                hasPermission('viewTable') && (
                                    <MenuItem>
                                        <MenuItemLink text="Table Setting" to="/setting-manage/table/lists" />
                                    </MenuItem>
                                )
                            }

                            {
                                hasPermission('viewEmbed') && (
                                    <MenuItem>
                                        <MenuItemLink text="Embed Code" to="/setting-manage/embed/code" />
                                    </MenuItem>
                                )
                            }

                        </MenuSub>
                    </MenuItem>
                )
            }

            {
                hasPermission('viewPaymentLog') && (
                    <MenuItem>
                        <MenuItemLink icon="sign-dollar" text="Payments" to="/payment-manage" />
                    </MenuItem>
                )
            }



            {
                hasPermission('campaign--') && (
                    <MenuItem>
                        <MenuItemLink icon="disk" text="Campaign" to="/campaign-manage/lists" />
                    </MenuItem>
                )
            }



            {
                (hasPermission('viewSubManager') && uInfo().type == 'Manager') && (
                    <MenuItem>
                        <MenuItemLink icon="user-list-fill" text="Team" to="/user-manage/submanager/lists" />
                    </MenuItem>
                )
            }

            {
                (hasPermission('viewDocuments') && uInfo().type == 'Reseller') && (
                    <MenuItem>
                        <MenuItemLink icon="file-doc" text="Documents" to="/document-manage/lists" />
                    </MenuItem>
                )
            }

            {
                hasPermission('reports----') && (

                    <MenuItem sub>
                        <MenuItemLink icon="files-fill" text="Reports" onClick={menuToggle} sub />
                        <MenuSub>
                            {
                                hasPermission('viewResellerlogs') && (
                                    <MenuItem>
                                        <MenuItemLink text="WL Reseller logs" to="/report-manage/reseller/logs" />
                                    </MenuItem>
                                )
                            }

                            {
                                hasPermission('viewManagerlogs') && (
                                    <MenuItem>
                                        <MenuItemLink text="Client logs" to="/report-manage/manager/logs" />
                                    </MenuItem>
                                )
                            }

                            {
                                hasPermission('viewMemberlogs') && (
                                    <MenuItem>
                                        <MenuItemLink text="Member logs" to="/report-manage/member/logs" />
                                    </MenuItem>
                                )
                            }

                            {
                                hasPermission('viewStafflogs') && (
                                    <MenuItem>
                                        <MenuItemLink text="Staff logs" to="/report-manage/staff/logs" />
                                    </MenuItem>
                                )
                            }

                            {
                                hasPermission('viewResellerloginHistory') && (
                                    <MenuItem>
                                        <MenuItemLink text="Login History Reseller" to="/report-manage/reseller/login/history" />
                                    </MenuItem>
                                )
                            }

                            {
                                hasPermission('viewManagerloginHistory') && (
                                    <MenuItem>
                                        <MenuItemLink text="Login History" to="/report-manage/manager/login/history" />
                                    </MenuItem>
                                )
                            }
                        </MenuSub>
                    </MenuItem>
                )
            }



            {
                (hasPermission('viewContactslogs') || hasPermission('viewAppointmentslogs') || hasPermission('viewPaymentslogs')) && (
                    <MenuItem sub>
                        <MenuItemLink icon="reports" text="Reports" onClick={menuToggle} sub />
                        <MenuSub>
                            {
                                hasPermission('viewContactslogs') && (
                                    <MenuItem>
                                        <MenuItemLink text="Contacts" to="/reports-manage/contacts" />
                                    </MenuItem>
                                )
                            }

                            {
                                hasPermission('viewAppointmentslogs') && (
                                    <MenuItem>
                                        <MenuItemLink text="Appointments" to="/reports-manage/appointments" />
                                    </MenuItem>
                                )
                            }

                            {
                                hasPermission('viewPaymentslogs') && (
                                    <MenuItem>
                                        <MenuItemLink text="Payments" to="/reports-manage/payments" />
                                    </MenuItem>
                                )
                            }
                        </MenuSub>
                    </MenuItem>
                )
            }

            {
                hasPermission('setting') && (

                    <MenuItem sub>
                        <MenuItemLink icon="setting-alt" text="Settings" onClick={menuToggle} sub />
                        <MenuSub>
                            {
                                (hasPermission('viewEmailTemplate') || hasPermission('viewSMSTemplate') || hasPermission('viewVoicemailTemplate') || hasPermission('viewScriptTemplate')) && (
                                    <MenuItem>
                                        {
                                            hasPermission('viewEmailTemplate') ?
                                                <MenuItemLink text="Templates" to="/template-manage/email/lists" />
                                                :
                                                hasPermission('viewSMSTemplate') ?
                                                    <MenuItemLink text="Templates" to="/template-manage/sms/lists" />
                                                    :
                                                    hasPermission('viewVoicemailTemplate') ?
                                                        <MenuItemLink text="Templates" to="/template-manage/voicemail/lists" />
                                                        :

                                                        <MenuItemLink text="Templates" to="/template-manage/script/lists" />

                                        }
                                    </MenuItem>
                                )
                            }

                            {
                                hasPermission('viewPhoneNumbers') && (
                                    <MenuItem>
                                        <MenuItemLink text="Phone Numbers" to="/settings/phone/numbers" />
                                    </MenuItem>
                                )
                            }

                            {
                                hasPermission('viewPipelines') && (
                                    <MenuItem>
                                        <MenuItemLink text="Pipelines" to="/pipelines-manage/lists" />
                                    </MenuItem>
                                )
                            }
                            {
                                hasPermission('viewDomains') && (
                                    <MenuItem>
                                        <MenuItemLink text="Domains" to="/domains-manage/lists" />
                                    </MenuItem>
                                )
                            }
                            {
                                hasPermission('viewProposals') && (
                                    <MenuItem>
                                        <MenuItemLink text="Proposals" to="/proposals-manage/lists" />
                                    </MenuItem>
                                )
                            }
                            {
                                hasPermission('viewSequence') && (
                                    <MenuItem>
                                        <MenuItemLink text="Sequence" to="/sequence-manage/lists" />
                                    </MenuItem>
                                )
                            }

                            {
                                hasPermission('viewDocuments') && (
                                    <MenuItem>
                                        <MenuItemLink text="Documents" to="/document-manage/lists" />
                                    </MenuItem>
                                )
                            }

                            {
                                hasPermission('viewDisclaimer') && (
                                    <MenuItem>
                                        <MenuItemLink text="Disclaimer" to="/cms-manage/disclaimer/lists" />
                                    </MenuItem>
                                )
                            }

                            {
                                hasPermission('viewGroup') && (
                                    <MenuItem>
                                        <MenuItemLink text="Tags" to={'/tags-manage/lists'} />
                                    </MenuItem>
                                )
                            }
                            {/* {
                                hasPermission('viewGroup') && (
                                    <MenuItem>
                                        <MenuItemLink text="Groups & Tags" to={'/group-manage/lists'} />
                                    </MenuItem>
                                )
                            }
                            {
                                hasPermission('viewCategory') && (
                                    <MenuItem>
                                        <MenuItemLink text="Job Categories" to={'/jobs/category/lists'} />
                                    </MenuItem>
                                )
                            } */}

                            {
                                hasPermission('viewLeadsFilter') && (
                                    <MenuItem>
                                        <MenuItemLink text="List & Filters" to={'/crm-manage/leads/filters/lists'} />
                                    </MenuItem>

                                )
                            }

                            {
                                hasPermission('viewLeadsForms') && (
                                    <MenuItem>
                                        <MenuItemLink text="Contact Forms" to={'/crm-manage/leads/forms/lists'} />
                                    </MenuItem>
                                )
                            }

                            {
                                hasPermission('viewLeadsFunnels') && (
                                    <MenuItem>
                                        <MenuItemLink text="Contact Funnels" to={'/crm-manage/leads/funnels/lists'} />
                                    </MenuItem>
                                )
                            }

                            {
                                hasPermission('viewRole') && (
                                    <MenuItem>
                                        <MenuItemLink text="Roles" to="/role-manage/list" />
                                    </MenuItem>
                                )
                            }

                            {/* {
                                hasPermission('products') && (
                                    <MenuItem>
                                        <MenuItemLink text="Products" to="/products-manage/lists" />
                                    </MenuItem>
                                )
                            }

                            {
                                hasPermission('viewCheckoutLinks') && (
                                    <MenuItem>
                                        <MenuItemLink text="Checkout Links" to="/checkout-links-manage/lists" />
                                    </MenuItem>
                                )
                            } */}

                        </MenuSub>
                    </MenuItem>
                )
            }


            {/* <MenuHeading text="misc pages" />
            <MenuItem sub>
                <MenuItemLink icon="signin" text="Auth Pages" onClick={menuToggle} sub />
                <MenuSub>
                    <MenuItem>
                        <MenuItemLink text="Auth Register" to="/auths/auth-register" />
                        <MenuItemLink text="Auth Login" to="/auths/auth-login" />
                        <MenuItemLink text="Forgot Password" to="/auths/auth-reset" />
                    </MenuItem>
                </MenuSub>
            </MenuItem>
            <MenuItem>
                <MenuItemLink icon="files" text="Page 404" to="/not-found" />
            </MenuItem> */}
        </MenuList>
    )
}

export default Menu