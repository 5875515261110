import React from 'react'
import { Routes, Route, useNavigate } from "react-router-dom";
import ProtectedRoute from './ProtectedRoute';
import PortalProtectedRoute from './PortalProtectedRoute';

import { AuthProvider } from '../AuthContext';

import { ScrollToTop } from '../components';

//Pages
import Blank from '../pages/Blank';
import Home from '../pages/Home';
import Blog from '../pages/Blog';


// user manage
import UserList from '../pages/user-manage/reseller/Lists';
import UserCards from '../pages/user-manage/UserCards';
import UserProfile from '../pages/user-manage/UserProfile';
// import UserEdit from '../pages/user-manage/UserEdit';

// admin
import Profile from '../pages/admin/Profile';
import ProfileSettings from '../pages/admin/ProfileSettings';



// auths pages
import AuthRegister from '../pages/auths/AuthRegister';
import AuthLogin from '../pages/auths/AuthLogin';
import PortalAuthLogin from '../pages/auths/PortalAuthLogin';
import AuthReset from '../pages/auths/AuthReset';

import NotFound from '../pages/error/NotFound';
import RoleCreate from '../pages/role-manage/Create';
import RoleList from '../pages/role-manage/Lists';
import RoleEdit from '../pages/role-manage/Edit';


import UserCreate from '../pages/user-manage/reseller/Create';
import UserEdit from '../pages/user-manage/reseller/Edit';

import GroupCreate from '../pages/group-manage/Create';
import GroupEdit from '../pages/group-manage/Edit';
import GroupList from '../pages/group-manage/Lists';
import TagsList from '../pages/tags-manage/Lists';
import TagsCreate from '../pages/tags-manage/Create';
import TagsEdit from '../pages/tags-manage/Edit';
import CustomFields from '../pages/crm-manage/CustomFields';
import ResellerList from '../pages/package-manage/reseller/Lists';
import ManagerList from '../pages/package-manage/manager/Lists';
import ResellerCreate from '../pages/package-manage/reseller/Create';
import ResellerEdit from '../pages/package-manage/reseller/Edit';
import ManagerCreate from '../pages/package-manage/manager/Create';
import ManagerEdit from '../pages/package-manage/manager/Edit';
import CreditList from '../pages/package-manage/credit/Lists';
import CreditCreate from '../pages/package-manage/credit/Create';
import CreditEdit from '../pages/package-manage/credit/Edit';
import StaffEdit from '../pages/user-manage/staff/Edit';
import StaffCreate from '../pages/user-manage/staff/Create';
import StaffList from '../pages/user-manage/staff/Lists';

import ManagerUserList from '../pages/user-manage/manager/Lists';
import ManagerUserCreate from '../pages/user-manage/manager/Create';
import ManagerUserEdit from '../pages/user-manage/manager/Edit';
import ResellerCredit from '../pages/credit-manage/Reseller';
import ManagerCredit from '../pages/credit-manage/Manager';
import ActivityLogs from '../pages/report-manage/ActivityLogs';
import LoginHistory from '../pages/report-manage/LoginHistory';
import PagesCms from '../pages/cms-manage/pages/Lists';
import CreatePagesCms from '../pages/cms-manage/pages/Create';
import EditPagesCms from '../pages/cms-manage/pages/Edit';
import Voip from '../pages/setting-manage/Voip';
import Email from '../pages/setting-manage/Email';
import Payment from '../pages/setting-manage/Payment';
import Table from '../pages/setting-manage/Table';
import Embed from '../pages/setting-manage/Embed';
import AuthToggle from '../pages/auths/AuthToggle';
import SubManagerList from '../pages/user-manage/subManager/Lists';
import SubManagerCreate from '../pages/user-manage/subManager/Create';
import SubManagerEdit from '../pages/user-manage/subManager/Edit';
import LeadList from '../pages/crm-manage/leads/Lists';
import LeadCreate from '../pages/crm-manage/leads/Create';
import LeadEdit from '../pages/crm-manage/leads/Edit';

import ContactList from '../pages/crm-manage/contacts/Lists';
import ContactCreate from '../pages/crm-manage/contacts/Create';
import ContactEdit from '../pages/crm-manage/contacts/Edit';

import LeadImport from '../pages/crm-manage/leads/Import';
import ExportContact from '../pages/export-request/Lists';
import RecycleBin from '../pages/crm-manage/leads/RecycleBin';

import LeadFormsList from '../pages/crm-manage/leads/forms/Lists';
import LeadFormsCreate from '../pages/crm-manage/leads/forms/Create';
import LeadFormsEdit from '../pages/crm-manage/leads/forms/Edit';

import LeadFiltersList from '../pages/crm-manage/leads/filters/Lists';
import LeadFiltersCreate from '../pages/crm-manage/leads/filters/Create';
import LeadFiltersEdit from '../pages/crm-manage/leads/filters/Edit'

import LeadFormsDetails from '../pages/crm-manage/leads/forms/FormDetails';
import EmailList from '../pages/template-manage/email/Lists';
import EmailTemplateList from '../pages/template-manage/email/TemplateList';
import EmailTemplateCreate from '../pages/template-manage/email/Create';
import EditEmailTemplate from '../pages/template-manage/email/Edit';
import SMSList from '../pages/template-manage/sms/Lists';
import SMSTemplateCreate from '../pages/template-manage/sms/Create';
import EditSMSTemplate from '../pages/template-manage/sms/Edit';
import VoiceMailList from '../pages/template-manage/voicemail/Lists';
import VoiceMailTemplateCreate from '../pages/template-manage/voicemail/Create';
import EditVoiceMailTemplate from '../pages/template-manage/voicemail/Edit';
import ScriptList from '../pages/template-manage/script/Lists';
import ScriptTemplateCreate from '../pages/template-manage/script/Create';
import EditScriptTemplate from '../pages/template-manage/script/Edit';
import ConsumptionCriteria from '../pages/credit-manage/ConsumptionCriteria';
import CamapignList from '../pages/campaign-manage/Lists';
import PhoneNumbers from '../pages/settings/PhoneNumbers';
import ProfileSetting from '../pages/settings/Profile';
import LeadDetails from '../pages/crm-manage/leads/Details';
import CampaignCreate from '../pages/campaign-manage/Create';
import CampaignEdit from '../pages/campaign-manage/Edit';
import Flow from '../pages/campaign-manage/Flow';
import LocationList from '../pages/location-manage/Lists';
import LocationCreate from '../pages/location-manage/Create';
import LocationEdit from '../pages/location-manage/Edit';
import CalendarManageList from '../pages/calendar-manage/Lists';
import CalendarManageCreate from '../pages/calendar-manage/Create';
import CalendarManageEdit from '../pages/calendar-manage/Edit';
import CalendarManageView from '../pages/calendar-manage/View';

import CalendarAppointmentList from '../pages/calendar-manage/appointments/Lists';
import CalendarAppointmentCreate from '../pages/calendar-manage/appointments/Create';
import CalendarAppointmentEdit from '../pages/calendar-manage/appointments/Edit';

import CalendarAppointmentStatusList from '../pages/calendar-manage/appointments/status/Lists';
import CalendarAppointmentStatusCreate from '../pages/calendar-manage/appointments/status/Create';
import CalendarAppointmentStatusEdit from '../pages/calendar-manage/appointments/status/Edit';
import CallComponent from '../pages/Plivo/Call';
import PipelineList from '../pages/pipeline-manage/Lists';
import PipelineView from '../pages/pipeline-manage/View';
import ProposalList from '../pages/proposal-manage/Lists';
import ProposalView from '../pages/proposal-manage/View';
import ProductList from '../pages/product-manage/Lists';
import CheckoutLinkList from '../pages/checkout-links-manage/Lists';
import CheckoutView from '../pages/checkout-links-manage/View';
import DomainList from '../pages/domain-manage/Lists';
import DomainView from '../pages/domain-manage/View';
import PaymentView from '../pages/payments-manage/View';
import PaymentIndex from '../pages/payments-manage/Index';
import PaymentSetting from '../pages/payments-manage/Settings';
import SequenceList from '../pages/sequence-manage/Lists';
import SequenceCreate from '../pages/sequence-manage/Create';
import SequencEdit from '../pages/sequence-manage/Edit';
import SequenceFlow from '../pages/sequence-manage/Flow';
import CalendarAutoResponderList from '../pages/calendar-manage/autoresponder/Lists';
import CalendarAutoResponderSeries from '../pages/calendar-manage/autoresponder/Series';
import GlobalTemplate from '../pages/template-manage/GlobalTemplate';
import Portal from '../pages/lead-portal';
import PortalDashboard from '../pages/lead-portal/lead-portal-dashboard';
import ConnectGmailPortal from '../pages/lead-portal/connect-gmail';
import ReportsContacts from '../pages/reports-manage/Contacts';
import ReportsAppointments from '../pages/reports-manage/Appointments';
import ReportsPayment from '../pages/reports-manage/Payments';
import DocumentList from '../pages/document-manage/Lists';
import DocumentCreate from '../pages/document-manage/Create';
import DocumentEdit from '../pages/document-manage/Edit';
import LeadFormView from '../pages/crm-manage/leads/forms/View';
import LeadFunnelsList from '../pages/crm-manage/leads/funnels/Lists';
import LeadFunnelsCreate from '../pages/crm-manage/leads/funnels/Create';
import LeadFunnelsEdit from '../pages/crm-manage/leads/funnels/Edit';
import LeadFunnelBuilder from '../pages/crm-manage/leads/funnels/FunnelBuilder';
import FunnelDetails from '../pages/crm-manage/leads/funnels/FormDetails';

import JobList from '../pages/jobs/Lists';
import JobCreate from '../pages/jobs/Create';
import JobImport from '../pages/jobs/Import';
import JobEdit from '../pages/jobs/Edit';
import JobDetails from '../pages/jobs/Details';
import CategoryCreate from '../pages/jobs/category/Create';
import CategoryEdit from '../pages/jobs/category/Edit';
import CategoryList from '../pages/jobs/category/Lists';
import JobCustomFields from '../pages/jobs/CustomFields';

function Router() {
  const navigate = useNavigate();
  return (
    <ScrollToTop>
      <AuthProvider>
        <Routes>
          <Route path="blank" element={<Blank />} />
          <Route path="call-test" element={<CallComponent />} />


          <Route path="/" element={<ProtectedRoute element={<Home />} />} />

          <Route path="/home" element={<ProtectedRoute element={<Home />} />} />

          <Route path="/blogs" element={<ProtectedRoute element={<Blog />} />} />

          <Route path="/global-template" element={<ProtectedRoute element={<GlobalTemplate />} />} />

          <Route path="settings">
            <Route path="profile/:id" element={<ProtectedRoute requiredPermission={''} element={<ProfileSetting />} />} />
            <Route path="phone/numbers" element={<ProtectedRoute requiredPermission={''} element={<PhoneNumbers />} />} />
          </Route>

          <Route path="reports-manage">
            <Route path="contacts" element={<ProtectedRoute requiredPermission={'viewContactslogs'} element={<ReportsContacts />} />} />
            <Route path="appointments" element={<ProtectedRoute requiredPermission={'viewAppointmentslogs'} element={<ReportsAppointments />} />} />
            <Route path="payments" element={<ProtectedRoute requiredPermission={'viewPaymentslogs'} element={<ReportsPayment />} />} />
          </Route>

          <Route path="sequence-manage">
            <Route path="lists" element={<ProtectedRoute requiredPermission={'viewSequence'} element={<SequenceList />} />} />
            <Route path="create" element={<ProtectedRoute requiredPermission={'createSequence'} element={<SequenceCreate />} />} />
            <Route path="edit/:id" element={<ProtectedRoute requiredPermission={'editSequence'} element={<SequencEdit />} />} />
            <Route path="flow/:id/:section" element={<ProtectedRoute requiredPermission={''} element={<SequenceFlow />} />} />

          </Route>

          <Route path="document-manage">
            <Route path="lists" element={<ProtectedRoute requiredPermission={'viewDocuments'} element={<DocumentList />} />} />
            <Route path="create" element={<ProtectedRoute requiredPermission={'createDocuments'} element={<DocumentCreate />} />} />
            <Route path="edit/:id" element={<ProtectedRoute requiredPermission={'editDocuments'} element={<DocumentEdit />} />} />
          </Route>


          <Route path="user-manage">
            <Route path='reseller'>
              <Route path="lists" element={<ProtectedRoute requiredPermission={'viewReseller'} element={<UserList />} />} />
              <Route path="create" element={<ProtectedRoute requiredPermission={'createReseller'} element={<UserCreate />} />} />
              <Route path="edit/:id" element={<ProtectedRoute requiredPermission={'editReseller'} element={<UserEdit />} />} />
            </Route>

            <Route path='manager'>
              <Route path="lists" element={<ProtectedRoute requiredPermission={'viewManager'} element={<ManagerUserList />} />} />
              <Route path="create" element={<ProtectedRoute requiredPermission={'createManager'} element={<ManagerUserCreate />} />} />
              <Route path="edit/:id" element={<ProtectedRoute requiredPermission={'editManager'} element={<ManagerUserEdit />} />} />
            </Route>

            <Route path='staff'>
              <Route path="lists" element={<ProtectedRoute requiredPermission={'viewStaff'} element={<StaffList />} />} />
              <Route path="create" element={<ProtectedRoute requiredPermission={'createStaff'} element={<StaffCreate />} />} />
              <Route path="edit/:id" element={<ProtectedRoute requiredPermission={'editStaff'} element={<StaffEdit />} />} />
            </Route>

            <Route path='submanager'>
              <Route path="lists" element={<ProtectedRoute requiredPermission={'viewSubManager'} element={<SubManagerList />} />} />
              <Route path="create" element={<ProtectedRoute requiredPermission={'createSubManager'} element={<SubManagerCreate />} />} />
              <Route path="edit/:id" element={<ProtectedRoute requiredPermission={'editSubManager'} element={<SubManagerEdit />} />} />
            </Route>

            <Route path="user-cards" element={<ProtectedRoute requiredPermission={'view_users'} element={<UserCards />} />} />
            <Route path="user-profile/:id" element={<ProtectedRoute requiredPermission={'view_user_profile'} element={<UserProfile />} />} />
            <Route path="user-edit/:id" element={<ProtectedRoute requiredPermission={'edit_user'} element={<UserEdit />} />} />
          </Route>

          <Route path="credit-manage">
            <Route path="reseller" element={<ProtectedRoute requiredPermission={'viewCreditFreeReseller'} element={<ResellerCredit />} />} />
            <Route path="manager" element={<ProtectedRoute requiredPermission={'viewCreditFreeManager'} element={<ManagerCredit />} />} />
            <Route path="consumption/criteria" element={<ProtectedRoute requiredPermission={'viewConsumptionCriteria'} element={<ConsumptionCriteria />} />} />

          </Route>

          <Route path="report-manage">
            <Route path=":type/logs" element={<ProtectedRoute requiredPermission={['viewResellerlogs', 'viewManagerlogs', 'viewMemberlogs', 'viewStafflogs']} element={<ActivityLogs />} />} />
            <Route path=":type/login/history" element={<ProtectedRoute requiredPermission={['viewResellerloginHistory', 'viewManagerloginHistory']} element={<LoginHistory />} />} />
          </Route>

          <Route path="cms-manage">
            <Route path=":type/lists/:id?" element={<ProtectedRoute requiredPermission={['viewDisclaimer', 'viewTerms', 'viewPrivacy', 'viewNotification', 'viewProposals']} element={<PagesCms />} />} />
            <Route path=":type/create/:id?" element={<ProtectedRoute requiredPermission={['createDisclaimer', 'createTerms', 'createPrivacy', 'createNotification', 'createProposals']} element={<CreatePagesCms />} />} />
            <Route path=":type/:action/:id/:proposal_id?" element={<ProtectedRoute requiredPermission={['editDisclaimer', 'editTerms', 'editPrivacy', 'editNotification', 'editProposals']} element={<EditPagesCms />} />} />
          </Route>

          <Route path="template-manage">
            <Route path='email'>
              <Route path="template/lists" element={<ProtectedRoute requiredPermission={'createEmailTemplate'} element={<EmailTemplateList />} />} />
              <Route path="lists" element={<ProtectedRoute requiredPermission={'viewEmailTemplate'} element={<EmailList />} />} />
              <Route path="create/:id" element={<ProtectedRoute requiredPermission={'createEmailTemplate'} element={<EmailTemplateCreate />} />} />
              <Route path="edit/:id" element={<ProtectedRoute requiredPermission={'editEmailTemplate'} element={<EditEmailTemplate />} />} />
            </Route>


            <Route path='sms'>
              <Route path="lists" element={<ProtectedRoute requiredPermission={'viewSMSTemplate'} element={<SMSList />} />} />
              <Route path="create" element={<ProtectedRoute requiredPermission={'createSMSTemplate'} element={<SMSTemplateCreate />} />} />
              <Route path="edit/:id" element={<ProtectedRoute requiredPermission={'editSMSTemplate'} element={<EditSMSTemplate />} />} />
            </Route>


            <Route path='voicemail'>
              <Route path="lists" element={<ProtectedRoute requiredPermission={'viewVoicemailTemplate'} element={<VoiceMailList />} />} />
              <Route path="create" element={<ProtectedRoute requiredPermission={'createVoicemailTemplate'} element={<VoiceMailTemplateCreate />} />} />
              <Route path="edit/:id" element={<ProtectedRoute requiredPermission={'editVoicemailTemplate'} element={<EditVoiceMailTemplate />} />} />
            </Route>

            <Route path='script'>
              <Route path="lists" element={<ProtectedRoute requiredPermission={'viewScriptTemplate'} element={<ScriptList />} />} />
              <Route path="create" element={<ProtectedRoute requiredPermission={'createScriptTemplate'} element={<ScriptTemplateCreate />} />} />
              <Route path="edit/:id" element={<ProtectedRoute requiredPermission={'editScriptTemplate'} element={<EditScriptTemplate />} />} />
            </Route>
          </Route>

          <Route path="setting-manage">
            <Route path="voip/lists" element={<ProtectedRoute requiredPermission={'viewVoip'} element={<Voip />} />} />
            <Route path="email/lists" element={<ProtectedRoute requiredPermission={'viewEmail'} element={<Email />} />} />
            <Route path="payment/lists" element={<ProtectedRoute requiredPermission={'viewPayment'} element={<Payment />} />} />
            <Route path="table/lists" element={<ProtectedRoute requiredPermission={'viewTable'} element={<Table />} />} />
            <Route path="embed/code" element={<ProtectedRoute requiredPermission={'viewEmbed'} element={<Embed />} />} />
          </Route>

          <Route path="campaign-manage">
            <Route path="lists" element={<ProtectedRoute requiredPermission={''} element={<CamapignList />} />} />
            <Route path="create" element={<ProtectedRoute requiredPermission={''} element={<CampaignCreate />} />} />
            <Route path="edit/:id" element={<ProtectedRoute requiredPermission={''} element={<CampaignEdit />} />} />
            <Route path="details/:id/:type" element={<ProtectedRoute requiredPermission={''} element={<CampaignEdit />} />} />
            <Route path="details/:id/:type/ivr/update/:ivr_id" element={<ProtectedRoute requiredPermission={''} element={<CampaignEdit />} />} />

            <Route path="flow/:id/:section/:group_id/:automation_id?" element={<ProtectedRoute requiredPermission={''} element={<Flow />} />} />

          </Route>


          <Route path="role-manage">
            <Route path="list" element={<ProtectedRoute requiredPermission={'viewRole'} element={<RoleList />} />} />
            <Route path="create" element={<ProtectedRoute requiredPermission={'createRole'} element={<RoleCreate />} />} />
            <Route path="edit/:id" element={<ProtectedRoute requiredPermission={'editRole'} element={<RoleEdit />} />} />
          </Route>

          <Route path="group-manage">
            <Route path="lists" element={<ProtectedRoute requiredPermission={'viewGroup'} element={<GroupList />} />} />
            <Route path="create" element={<ProtectedRoute requiredPermission={'createGroup'} element={<GroupCreate />} />} />
            <Route path="edit/:id" element={<ProtectedRoute requiredPermission={'editGroup'} element={<GroupEdit />} />} />
          </Route>

          <Route path="tags-manage">
            <Route path="lists" element={<ProtectedRoute requiredPermission={'viewGroup'} element={<TagsList />} />} />
            <Route path="create" element={<ProtectedRoute requiredPermission={'createGroup'} element={<TagsCreate />} />} />
            <Route path="edit/:id" element={<ProtectedRoute requiredPermission={'editGroup'} element={<TagsEdit />} />} />
          </Route>

          <Route path="crm-manage">
            <Route path='leads'>
              <Route path="lists" element={<ProtectedRoute requiredPermission={'viewLeads'} element={<LeadList />} />} />
              <Route path="create/:id?" element={<ProtectedRoute requiredPermission={'createLeads'} element={<LeadCreate />} />} />
              <Route path="edit/:id" element={<ProtectedRoute requiredPermission={'editLeads'} element={<LeadEdit />} />} />
              <Route path="dialer/:id" element={<ProtectedRoute requiredPermission={''} element={<LeadDetails />} />} />
              <Route path="recyclebin" element={<ProtectedRoute requiredPermission={'viewLeads'} element={<RecycleBin />} />} />
              <Route path="export" element={<ProtectedRoute requiredPermission={'viewLeads'} element={<ExportContact />} />} />

              <Route path="import" element={<ProtectedRoute requiredPermission={'createLeads'} element={<LeadImport />} />} />
              <Route path='forms'>
                <Route path="lists" element={<ProtectedRoute requiredPermission={'viewLeadsForms'} element={<LeadFormsList />} />} />
                <Route path="create" element={<ProtectedRoute requiredPermission={'createLeadsForms'} element={<LeadFormsCreate />} />} />
                <Route path="edit/:id" element={<ProtectedRoute requiredPermission={'editLeadsForms'} element={<LeadFormsEdit />} />} />
                <Route path="view/:id/:leadId" element={<ProtectedRoute requiredPermission={'viewLeadsForms'} element={<LeadFormView />} />} />
              </Route>
              <Route path='filters'>
                <Route path="lists/:page?" element={<ProtectedRoute requiredPermission={'viewLeads'} element={<LeadFiltersList />} />} />
                <Route path="create/:page?" element={<ProtectedRoute requiredPermission={'viewLeads'} element={<LeadFiltersCreate />} />} />
                <Route path="edit/:id/:page?" element={<ProtectedRoute requiredPermission={'viewLeads'} element={<LeadFiltersEdit />} />} />
              </Route>


              <Route path='funnels'>
                <Route path="lists" element={<ProtectedRoute requiredPermission={'viewLeadsFunnels'} element={<LeadFunnelsList />} />} />
                <Route path="create" element={<ProtectedRoute requiredPermission={'createLeadsFunnels'} element={<LeadFunnelsCreate />} />} />
                <Route path="edit/:id" element={<ProtectedRoute requiredPermission={'editLeadsFunnels'} element={<LeadFunnelsEdit />} />} />
                <Route path="builder/:id" element={<ProtectedRoute requiredPermission={'editLeadsFunnels'} element={<LeadFunnelBuilder />} />} />
                
                <Route path="view/:id/:leadId" element={<ProtectedRoute requiredPermission={'viewLeadsFunnels'} element={<LeadFunnelsList />} />} />
              </Route>

            </Route>

            <Route path='contacts'>
              <Route path="lists" element={<ProtectedRoute requiredPermission={'viewLeads'} element={<ContactList />} />} />
              <Route path="create" element={<ProtectedRoute requiredPermission={'createLeads'} element={<ContactCreate />} />} />
              <Route path="edit/:id" element={<ProtectedRoute requiredPermission={'editLeads'} element={<ContactEdit />} />} />
            </Route>

            <Route path="custom-fields" element={<ProtectedRoute requiredPermission={'viewCustomFields'} element={<CustomFields />} />} />
          </Route>
          <Route path="jobs"> 
            <Route path="lists" element={<ProtectedRoute requiredPermission={'viewJobs'} element={<JobList />} />} />
            <Route path="create" element={<ProtectedRoute requiredPermission={'createJobs'} element={<JobCreate />} />} />
            <Route path="detail/:id" element={<ProtectedRoute requiredPermission={''} element={<JobDetails />} />} />
            <Route path="edit/:id" element={<ProtectedRoute requiredPermission={'editJobs'} element={<JobEdit />} />} />
            <Route path="import" element={<ProtectedRoute requiredPermission={'viewJobs'} element={<JobImport />} />} />
            <Route path="export" element={<ProtectedRoute requiredPermission={'viewJobs'} element={<ExportContact />} />} />

            <Route path="category">
              <Route path="lists" element={<ProtectedRoute requiredPermission={'viewCategory'} element={<CategoryList />} />} />
              <Route path="create" element={<ProtectedRoute requiredPermission={'createCategory'} element={<CategoryCreate />} />} />
              <Route path="edit/:id" element={<ProtectedRoute requiredPermission={'editCategory'} element={<CategoryEdit />} />} />
            </Route>
            <Route path="custom-fields" element={<ProtectedRoute requiredPermission={'viewJobCustomFields'} element={<JobCustomFields />} />} />
          </Route>
          <Route path="package-manage">
            <Route path='reseller'>
              <Route path="lists" element={<ProtectedRoute requiredPermission={'viewResellerPackage'} element={<ResellerList />} />} />
              <Route path="create" element={<ProtectedRoute requiredPermission={'createResellerPackage'} element={<ResellerCreate />} />} />
              <Route path="edit/:id" element={<ProtectedRoute requiredPermission={'editResellerPackage'} element={<ResellerEdit />} />} />

            </Route>

            <Route path='manager'>
              <Route path="lists" element={<ProtectedRoute requiredPermission={'viewManagerPackage'} element={<ManagerList />} />} />
              <Route path="create" element={<ProtectedRoute requiredPermission={'createManagerPackage'} element={<ManagerCreate />} />} />
              <Route path="edit/:id" element={<ProtectedRoute requiredPermission={'editManagerPackage'} element={<ManagerEdit />} />} />
            </Route>

            <Route path='credit'>
              <Route path="lists" element={<ProtectedRoute requiredPermission={'viewCreditPackage'} element={<CreditList />} />} />
              <Route path="create" element={<ProtectedRoute requiredPermission={'createCreditPackage'} element={<CreditCreate />} />} />
              <Route path="edit/:id" element={<ProtectedRoute requiredPermission={'editCreditPackage'} element={<CreditEdit />} />} />
            </Route>
          </Route>

          <Route path="location-manage">
            <Route path="lists" element={<ProtectedRoute requiredPermission={'viewLocation'} element={<LocationList />} />} />
            <Route path="create" element={<ProtectedRoute requiredPermission={'createLocation'} element={<LocationCreate />} />} />
            <Route path="edit/:id" element={<ProtectedRoute requiredPermission={'editLocation'} element={<LocationEdit />} />} />
          </Route>

          <Route path="calendar-manage">
            <Route path="lists" element={<ProtectedRoute requiredPermission={'viewCalendar'} element={<CalendarManageList />} />} />
            <Route path="create" element={<ProtectedRoute requiredPermission={'createCalendar'} element={<CalendarManageCreate />} />} />
            <Route path="edit/:id" element={<ProtectedRoute requiredPermission={'editCalendar'} element={<CalendarManageEdit />} />} />
            <Route path="view/:id" element={<ProtectedRoute requiredPermission={'editCalendar'} element={<CalendarManageView />} />} />
            <Route path='appointments'>
              <Route path="lists" element={<ProtectedRoute requiredPermission={'viewCalendarAppointment'} element={<CalendarAppointmentList />} />} />
              <Route path="create/:cal_id/:loc_id?" element={<CalendarAppointmentCreate navigate={navigate} />} />
              <Route path="edit/:id" element={<ProtectedRoute requiredPermission={'editCalendarAppointment'} element={<CalendarAppointmentEdit />} />} />
              <Route path="status">
                <Route path="lists" element={<ProtectedRoute requiredPermission={'viewCalendarAppointmentStatus'} element={<CalendarAppointmentStatusList />} />} />
                <Route path="create" element={<ProtectedRoute requiredPermission={'createCalendarAppointmentStatus'} element={<CalendarAppointmentStatusCreate />} />} />
                <Route path="edit/:id" element={<ProtectedRoute requiredPermission={'editCalendarAppointmentStatus'} element={<CalendarAppointmentStatusEdit />} />} />
              </Route>
            </Route>
            <Route path='responder'>
              <Route path="lists" element={<ProtectedRoute requiredPermission={'viewCalendarsAutoResponder'} element={<CalendarAutoResponderList />} />} />
              <Route path="series/:id" element={<ProtectedRoute requiredPermission={'viewCalendarsAutoResponder'} element={<CalendarAutoResponderSeries />} />} />

            </Route>
          </Route>

          <Route path="pipelines-manage">
            <Route path="lists" element={<ProtectedRoute requiredPermission={'viewPipelines'} element={<PipelineList />} />} />
            <Route path="view/:id" element={<ProtectedRoute requiredPermission={'viewPipelines'} element={<PipelineView />} />} />
          </Route>

          <Route path="proposals-manage">
            <Route path="lists" element={<ProtectedRoute requiredPermission={'viewProposals'} element={<ProposalList />} />} />
          </Route>

          <Route path="products-manage">
            <Route path="lists" element={<ProtectedRoute requiredPermission={'viewProducts'} element={<ProductList />} />} />
          </Route>
          <Route path="checkout-links-manage">
            <Route path="lists" element={<ProtectedRoute requiredPermission={'viewCheckoutLinks'} element={<CheckoutLinkList />} />} />
          </Route>
          <Route path="domains-manage">
            <Route path="lists" element={<ProtectedRoute requiredPermission={'viewDomains'} element={<DomainList />} />} />
            <Route path="view/:id" element={<ProtectedRoute requiredPermission={'viewDomains'} element={<DomainView />} />} />
          </Route>

          <Route path="payment-manage">
            <Route path="" element={<ProtectedRoute requiredPermission={'viewPaymentLog'} element={<PaymentIndex />} />} />
            <Route path="settings" element={<ProtectedRoute requiredPermission={'viewPayment'} element={<PaymentSetting />} />} />
          </Route>

          <Route path="lead-form/:id/:slug" element={<LeadFormsDetails />} />
          <Route path="proposal/:id/:slug" element={<ProposalView />} />
          <Route path="checkout/:id/:slug" element={<CheckoutView />} />
          <Route path="payment/:id/:slug" element={<PaymentView />} />
          <Route path="portal/dashboard" element={<PortalProtectedRoute element={<PortalDashboard />} />} />
          <Route path="portal/connect-gmail/:id/:slug"  element={<ConnectGmailPortal />} />
          <Route path="leads/portal/:id/:coach_id" element={<Portal />} />
          <Route path="portal/login" element={<PortalAuthLogin />} />

          <Route path="funnel/:id/:slug" element={<FunnelDetails />} />



          <Route path="admin">
            <Route path="profile" element={<Profile />} />
            <Route path="profile-settings" element={<ProfileSettings />} />
          </Route>


          <Route path="auths">
            <Route path="auth-register" element={<AuthRegister />} />
            <Route path="auth-toggle" element={<AuthToggle />} />
            <Route path="auth-login" element={<AuthLogin />} />
            <Route path="auth-reset" element={<AuthReset />} />
          </Route>

          <Route path="not-found" element={<NotFound />} />
          <Route path="*" element={<NotFound />} />
        </Routes>
      </AuthProvider>
    </ScrollToTop>

  )
}

export default Router;
