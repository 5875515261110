import { useState, useEffect } from "react";
import { Link, useParams } from 'react-router-dom';
import { Card, Button, Row, Col, Form, Spinner } from 'react-bootstrap';

import Layout from '../../../../layout/default';
import Block from '../../../../components/Block/Block';
import { Image, Icon, Media, Select, TimeZone } from '../../../../components';
import { sendRequest } from "../../../../utilities/api";
import $ from "jquery"

function FormCreate(props) {
    const { page } = useParams();
    const currentPage = page !== undefined ? page : 'leads';
    const [data, setData] = useState({});
    const [meta, setMeta] = useState({ coachPartners: [], tokens: [], groups: [], tags: [], services: [], categories: [] });
    const [loading, setLoading] = useState(false);
    const [customFields, setCustomFields] = useState([]);
    const [defaultFields, setDefaultFields] = useState([]);

    const getInitialColumnVisibility = () => {
        if (currentPage === "leads") {
            return {
                name: true,
                email: true,
                lead_number: true,
                address: true,
                company_name: true,
                checkboxes: true
            };
        } else if (currentPage === 'appointments') {
            return {
                'appointment_date': true,
                'lead_details.first_name': true,
                'created_by_user': true,
                'calendar.title': true,
                'duration': true,
                'appointment_status_title': true
            };
        } else if (currentPage === 'jobs') {
            return {
                'id': true,
                'record_owner': true,
                'category_title': true,
                'service_title': true,
                'tag_title': true,
                'pipeline': true,
                'milestone': true
            };
        }
    };
    const [columnVisibility, setColumnVisibility] = useState(getInitialColumnVisibility);


    const defaultColumn = [
        {
            name: "Lead ID",
            key: 'id',
        },
        {
            name: "Group",
            key: 'groups',
        },
        {
            name: "Tag",
            key: 'tags',
        },
        {
            name: "Full Name",
            key: 'name',
        },
        {
            name: "Email",
            key: 'email',
        },

        {
            name: "Phone",
            key: 'lead_number',
        },

        {
            name: "Mobile",
            key: 'mobile',
        },
        {
            name: "City",
            key: 'city',
        },

        {
            name: "State",
            key: 'state',
        },
        {
            name: "Zip",
            key: 'zip',
        },
        {
            name: "Address",
            key: 'address',
        },
        {
            name: "website",
            key: 'website',
        },
        {
            name: "Company",
            key: 'company_name',
        },
        {
            name: "Date of Birth",
            key: 'date_of_birth',
        },
    ];
    const jobColumn = [
        {
            name: "Job ID",
            key: 'id',
        },

        {
            name: "Record Owner",
            key: 'record_owner',
        },

        {
            name: "Related To",
            key: 'related_to_name',
        },

        {
            name: "Categories",
            key: 'category_title',
        },
        {
            name: "Services",
            key: 'service_title',
        },
        {
            name: "Tags",
            key: 'tag_title',
        },
        {
            name: "Pipeline",
            key: 'pipeline',
        },
        {
            name: "Milestone",
            key: 'milestone',
        },
        {
            name: "Amount",
            key: 'amount_formated',
        },
        {
            name: "Hours Open",
            key: 'open_days_hours',
        },
        {
            name: "Estimated Hours",
            key: 'estimated_hours',
        },
        {
            name: "Actual Hours",
            key: 'actual_hours',
        },
        {
            name: "Job Site",
            key: 'job_site',
        },
        {
            name: "Lookup Time",
            key: 'lookup_time',
        },
        {
            name: "Site Address",
            key: 'site_address',
        },
        {
            name: "Billing Address",
            key: 'billing_address',
        },
        {
            name: "Dated",
            key: 'dated',
        }



    ];
    const appointmentColumn = [
        {
            name: "Appointment Date/Time",
            key: 'appointment_date',
        },

        {
            name: "Appointment With",
            key: 'lead_details.first_name',
        },

        {
            name: "Created By",
            key: 'created_by_user',
        },

        {
            name: "Calendar",
            key: 'calendar.title',
        },

        {
            name: "Duration/minutes",
            key: 'duration',
        },

        {
            name: 'Status',
            key: 'appointment_status_title',
        },
        // {
        //     name: "Lead ID",
        //     key: 'lead_details.id',
        // },
        {
            name: "Group",
            key: 'lead_details.groups',
        },
        {
            name: "Tag",
            key: 'lead_details.tags',
        },
        {
            name: "Email",
            key: 'lead_details.email',
        },

        {
            name: "Phone",
            key: 'lead_details.phone',
        },

        {
            name: "Mobile",
            key: 'lead_details.mobile',
        },
        {
            name: "City",
            key: 'lead_details.city',
        },

        {
            name: "State",
            key: 'lead_details.state',
        },
        {
            name: "Zip",
            key: 'lead_details.zip',
        },
        {
            name: "Address",
            key: 'lead_details.address',
        },
        {
            name: "website",
            key: 'lead_details.website',
        },
        {
            name: "Company",
            key: 'lead_details.company_name',
        },
        {
            name: "Date of Birth",
            key: 'lead_details.date_of_birth',
        }
    ];

    const [userColumns, setUserColumns] = useState(currentPage === "leads" ? defaultColumn : currentPage === 'jobs' ? jobColumn : appointmentColumn);

    useEffect(() => {
        fetchMeta();
    }, []);
    const toggleColumnVisibility = (key, status) => {
        setLoading(false);
        setColumnVisibility((prevVisibility) => ({
            ...prevVisibility,
            [key]: status,
        }));

        setLoading(true);
    };
    const visibleColumns = userColumns.filter((column) => columnVisibility[column.key]);

    const fetchMeta = () => {
        const formData = new FormData();
        formData.append('type', currentPage);
        sendRequest('crm/leads/filters/meta/fetch', 'POST', formData).then(response => {
            if (response.status === true) {
                setMeta(response.data);
                setDefaultFields(page == 'leads' ? response.data.tokens.default_fields : response.data.tokens.job_default_fields);
                setLoading(true);
            }
        })
            .catch(error => {
                setLoading(true);
                console.error('POST request error:', error);
            });
    }


    const handleSubmit = async (event) => {
        event.preventDefault();
        const formData = new FormData(event.currentTarget);
        const submitData = new FormData();
        for (let [key, value] of formData.entries()) {
            submitData.append(key, value);
        }
        let selected_columns = [];
        visibleColumns.forEach((row) => {
            selected_columns.push(row.key);
        });
        submitData.append('selected_columns', JSON.stringify(selected_columns));
        submitData.append('type', currentPage);
        sendRequest('crm/leads/filters/create', 'POST', submitData)
            .then(response => {
                if (response.status === true) {
                    if (currentPage != 'leads') {
                        props.navigate(-1);
                    } else {
                        props.navigate('/crm-manage/leads/filters/lists');
                    }
                }
            })
            .catch(error => {
                console.error('POST request error:', error);
            });

    };

    const createUI = () => {

        return customFields.map((el, i) => (
            <div className={`border py-2 px-2 ${el.key ? el.key : 'd-none'}`} key={i} id={`element_${i}`}>
                <div className="row">
                    <div className="col-md-1 mb-3 d-none">
                        <div className="form-group">
                            <label className="visibilty-hidden"></label>

                            <input placeholder="Enter label" name={`filters[${i}][key]`} required="" id="label" type="text"
                                className="form-control" value={`${i == 0 ? 'Start' : 'Next'}`} />

                            <input type={'hidden'} name={`filters[${i}][selected_fields]`} value={customFields[i].selected_fields} />
                        </div>
                    </div>

                    {
                        i != 0 && (
                            <div className="col-md-2">
                                <div className="form-group">
                                    <label className="visibilty-hidden">Operands</label>
                                    <select className="select form-control"
                                        type="select"
                                        name={`filters[${i}][operator]`}
                                        defaultValue={customFields[i].operator || ''}
                                        required
                                    >
                                        <option value="AND">AND</option>
                                        <option value="OR">OR</option>
                                    </select>
                                </div>
                            </div>
                        )
                    }

                    {addContent(i, customFields[i].selected_fields)}

                    {
                        i != 0 && (
                            <div className="col-md-1" >
                                <label className="col-md-12"></label>
                                <Button
                                    type="button"
                                    className="btn btn-sm"
                                    color="secondary"
                                    onClick={() => removeClick(i)}>
                                    <Icon name="trash"> </Icon>
                                </Button>
                            </div>
                        )
                    }

                </div>
            </div>
        ))
    }
    const addContent = (i, type) => {
        if (type == 'Group') {
            return (
                <div key={i} className="col-md-8">
                    <div className="row">
                        <div className="col-md-3">
                            <div className="form-group">
                                <label>Type:</label>
                                <select defaultValue={customFields[i].field_type} name={`filters[${i}][field_type]`} className="form-control valid">
                                    <option value="with">With Specified Group</option>
                                    <option value="without">Without Specified Group</option>
                                </select>
                            </div>
                        </div>
                        <div className="col-md-6" >
                            <label>Group(s):</label>
                            <Select removeItemButton name={`filters[${i}][group_id][]`} multiple defaultValue={data.group_id} >
                                <option value="">Select Group(s)</option>
                                {
                                    meta.groups.map((row, i) => {
                                        return <option key={i} value={row.id}>{row.title}</option>
                                    })
                                }
                            </Select>
                        </div>
                    </div>
                </div>
            )
        } else if (type == 'Category') {
            return (
                <div key={i} className="col-md-8">
                    <div className="row">
                        <div className="col-md-3">
                            <div className="form-group">
                                <label>Type:</label>
                                <select defaultValue={customFields[i].field_type} name={`filters[${i}][field_type]`} className="form-control valid">
                                    <option value="with">With Specified Category</option>
                                    <option value="without">Without Specified Category</option>
                                </select>
                            </div>
                        </div>
                        <div className="col-md-6" >
                            <label>Tag(s):</label>
                            <Select removeItemButton name={`filters[${i}][tag_id][]`} multiple defaultValue={data.category_id} >
                                <option value="">Select Category(s)</option>
                                {
                                    meta.categories.map((row, i) => {
                                        return <option key={i} value={row.id}>{row.title}</option>
                                    })
                                }
                            </Select>
                        </div>
                    </div>
                </div>
            )
        } else if (type == 'Service') {
            return (
                <div key={i} className="col-md-8">
                    <div className="row">
                        <div className="col-md-3">
                            <div className="form-group">
                                <label>Type:</label>
                                <select defaultValue={customFields[i].field_type} name={`filters[${i}][field_type]`} className="form-control valid">
                                    <option value="with">With Specified Service</option>
                                    <option value="without">Without Specified Service</option>
                                </select>
                            </div>
                        </div>
                        <div className="col-md-6" >
                            <label>Servic(s):</label>
                            <Select removeItemButton name={`filters[${i}][service_id][]`} multiple defaultValue={data.service_id} >
                                <option value="">Select Service(s)</option>
                                {
                                    meta.services.map((row, i) => {
                                        return <option key={i} value={row.id}>{row.title}</option>
                                    })
                                }
                            </Select>
                        </div>
                    </div>
                </div>
            )
        } else if (type == 'Tag') {
            return (
                <div key={i} className="col-md-8">
                    <div className="row">
                        <div className="col-md-3">
                            <div className="form-group">
                                <label>Type:</label>
                                <select defaultValue={customFields[i].field_type} name={`filters[${i}][field_type]`} className="form-control valid">
                                    <option value="with">With Specified Tag</option>
                                    <option value="without">Without Specified Tag</option>
                                </select>
                            </div>
                        </div>
                        <div className="col-md-6" >
                            <label>Tag(s):</label>
                            <Select removeItemButton name={`filters[${i}][tag_id][]`} multiple defaultValue={data.tag_id} >
                                <option value="">Select Tag(s)</option>
                                {
                                    meta.tags.map((row, i) => {
                                        return <option key={i} value={row.id}>{row.title}</option>
                                    })
                                }
                            </Select>
                        </div>
                    </div>
                </div>
            )
        } else if (type == 'having') {
            return (
                <>
                    <div className="col-md-3" >
                        <label>Contact Having:</label>
                        <select required defaultValue={customFields[i].having} name={`filters[${i}][having]`} className="form-control">
                            <option value="1">Select Option</option>
                            <option value="2">Just Phone , No Email</option>
                            <option value="3">Just Email , No Phone</option>
                            <option value="4">Both Email And Phone</option>
                            <option value="5">No Email , No Phone</option>
                            <option value="6">Just Mobile , No Phone</option>
                            <option value="7">Just Phone , No Mobile</option>
                            <option value="8">Both Phone and Mobile</option>
                        </select>
                    </div>
                </>
            )
        } else if (type == 'custom_fields') {
            return (
                <>
                    <div className="col-md-3" >
                        <label>Custom Fields:</label>
                        <select defaultValue={customFields[i].custom_fields} name={`filters[${i}][custom_fields]`} className="form-control">
                            {
                                meta?.tokens?.custom_fields.map((row, i) => {
                                    return <option key={'custom_fields_' + i} value={row.key}>{row.label}</option>
                                })
                            }

                        </select>
                    </div>

                    <div className="col-md-3" >
                        <label>Value:</label>
                        <input required defaultValue={customFields[i].custom_fields_value} name={`filters[${i}][custom_fields_value]`} className="form-control" />

                    </div>
                </>
            )
        } else if (type == 'specified_text') {
            return (
                <>
                    <div className="col-md-6" >
                        <label>Specified Text:</label>
                        <input required defaultValue={customFields[i].specified_text} name={`filters[${i}][specified_text]`} className="form-control" />

                    </div>
                </>
            )
        } else if (type == 'added_by') {
            return (
                <div key={i} className="col-md-8">
                    <div className="row">

                        <div className="col-md-6" >
                            <label>Owner(s):</label>
                            <Select removeItemButton name={`filters[${i}][added_by][]`} defaultValue={data.added_by} >
                                <option value="">Select Team(s)</option>
                                {
                                    meta.coachPartners.map((row, i) => {
                                        return <option key={i} value={row.id}>{row.first_name} {row.last_name}</option>
                                    })
                                }
                            </Select>
                        </div>
                    </div>
                </div>
            )
        }
        else if (type == 'appointmentStatus') {
            return (
                <div key={i} className="col-md-8">
                    <div className="row">
                        <div className="col-md-5">
                            <div className="form-group">
                                <label>Type:</label>
                                <select defaultValue={customFields[i].field_type} name={`filters[${i}][field_type]`} className="form-control valid">
                                    <option value="with">With Specified Status</option>
                                    <option value="without">Without Specified Status</option>
                                </select>
                            </div>
                        </div>
                        <div className="col-md-6" >
                            <label>Appointment Status(s):</label>
                            <Select removeItemButton multiple name={`filters[${i}][status_id][]`} defaultValue={data.status_id} >
                                <option value="">Select Status(s)</option>
                                {
                                    meta.appointmentStatus.map((row, i) => {
                                        return <option key={i} value={row.id}>{row.title}</option>
                                    })
                                }
                            </Select>
                        </div>
                    </div>
                </div>
            )
        }
        else if (type == 'calendar') {
            return (
                <div key={i} className="col-md-8">
                    <div className="row">
                        <div className="col-md-5">
                            <div className="form-group">
                                <label>Type:</label>
                                <select defaultValue={customFields[i].field_type} name={`filters[${i}][field_type]`} className="form-control valid">
                                    <option value="with">With Specified calendar</option>
                                    <option value="without">Without Specified calendar</option>
                                </select>
                            </div>
                        </div>
                        <div className="col-md-6" >
                            <label>Calendar(s):</label>
                            <Select removeItemButton name={`filters[${i}][calendar_id][]`} multiple defaultValue={data.calendar_id} >
                                <option value="">Select Calendar(s)</option>
                                {
                                    meta.calendars.map((row, i) => {
                                        return <option key={i} value={row.id}>{row.title}</option>
                                    })
                                }
                            </Select>
                        </div>
                    </div>
                </div>
            )
        }
        else if (type == 'type') {
            return (
                <div key={i} className="col-md-8">
                    <div className="row">
                        <div className="col-md-3">
                            <div className="form-group">
                                <label>Type:</label>
                                <select defaultValue={customFields[i].field_type} name={`filters[${i}][field_type]`} className="form-control valid">
                                    <option value="with">With Specified Type</option>
                                    <option value="without">Without Specified Type</option>
                                </select>
                            </div>
                        </div>
                        <div className="col-md-6" >
                            <label>Client Type:</label>
                            <Select removeItemButton name={`filters[${i}][type][]`} defaultValue={data.type} >
                                <option value="">Select Type</option>
                                <option value={'0'}>Individual</option>
                                <option value={'1'}>Company</option>
                            </Select>
                        </div>
                    </div>
                </div>
            )
        } else {
            return (
                <>
                    <div className="col-md-3" >
                        <label>{customFields[i].selected_fields}:</label>
                        <select required defaultValue={customFields[i].operation} name={`filters[${i}][operation]`} className="form-control">
                            <option value="eq">Equal</option>
                            <option value="neq">Not Equal</option>
                            <option value="sw">Start With</option>
                            <option value="ew">End With</option>
                            <option value="con">Containing</option>
                        </select>
                    </div>

                    <div className="col-md-6" >
                        <label className="visibilty-hidden">Specified Text:</label>
                        <input required name={`filters[${i}][value]`} className="sm form-control" />

                    </div>
                </>
            )
        }
    }

    const OptionChange = () => {
        var options = document.getElementById('contact_fields').selectedOptions;
        var values = Array.from(options).map(({ value }) => value);

        values.forEach(element => {
            addClick(element)
        });
    }
    const findById = (array, type) => {
        let exist = false;
        for (const item of array) {
            if (item.selected_fields === type) return exist = true;
        }
        return exist;
    }
    const addClick = (type) => {
        let exist = findById(customFields, type);

        if (!exist) {
            setCustomFields(prevState => ([
                ...prevState,
                { label: "Start", flowId: "", key: "Start", selected_fields: type }
            ]));
        }
    }
    const removeClick = (i) => {
        let updatedCustomFields = [...customFields];
        updatedCustomFields[i] = {};
        $('#element_' + i).html('');
        setCustomFields(updatedCustomFields);
    };
    return (
        <Layout title="Filter Create" content="container">
            <div className="filter-create">
                <Block.Head>
                    <Block.HeadBetween>
                        <Block.HeadContent>
                            <Block.Title tag="h2">Add Filter</Block.Title>
                            <nav>
                                <ol className="breadcrumb breadcrumb-arrow mb-0">
                                    <li className="breadcrumb-item"><Link to="/">Home</Link></li>
                                    <li className="breadcrumb-item"><Link onClick={() => props.navigate(-1)}>Filters List</Link></li>
                                    <li className="breadcrumb-item active" aria-current="page">Create</li>
                                </ol>
                            </nav>
                        </Block.HeadContent>
                        <Block.HeadContent>


                            <button onClick={() => props.navigate(-1)} className="btn btn-sm btn-primary">
                                Back
                            </button>

                        </Block.HeadContent>
                    </Block.HeadBetween>
                </Block.Head>
                <Block>
                    {
                        !loading ? (
                            <Spinner animation="grow" variant="primary"></Spinner>
                        ) : (
                            <Card className="card-gutter-md">

                                <Card.Body>
                                    <div className="bio-block">
                                        <Form onSubmit={handleSubmit}>

                                            <div className={`bio-block`}>
                                                <Row className="g-3">
                                                    <Col md="4">
                                                        <Form.Group className="form-group">
                                                            <Form.Label htmlFor="email">Filter Name</Form.Label>
                                                            <div className="form-control-wrap">
                                                                <Form.Control type="text" name="name" required defaultValue={data.name} />
                                                            </div>
                                                        </Form.Group>
                                                    </Col>
                                                    <Col md="4">
                                                        <Form.Group className="form-group">
                                                            <Form.Label htmlFor="role">Shared With</Form.Label>
                                                            {meta?.coachPartners.length > 0 ? (
                                                                <Select name="permissions[]" multiple removeItemButton>
                                                                    <option value="">Select</option>
                                                                    {
                                                                        meta.coachPartners.map((row, i) => {
                                                                            return <option key={i} value={row.id}>{row.first_name} {row.last_name}</option>
                                                                        })
                                                                    }
                                                                </Select>
                                                            ) :
                                                                <Select name="permissions[]" multiple removeItemButton>
                                                                    <option value="">Select</option>
                                                                </Select>

                                                            }
                                                        </Form.Group>
                                                    </Col>
                                                    <Col md="4">
                                                        <Form.Group className="form-group">
                                                            <Form.Label htmlFor="role">Status</Form.Label>
                                                            <Select name="status" defaultValue={'Active'}>
                                                                <option value="Active">Active</option>
                                                                <option value="Inactive">Inactive</option>
                                                            </Select>
                                                        </Form.Group>
                                                    </Col>

                                                    {
                                                        currentPage == 'leads' ?

                                                            <Col md="3">
                                                                <select style={{ minHeight: 300 }} className="form-control valid" id="contact_fields" name="contact_fields[]" multiple="multiple" onChange={(e) => OptionChange()}>
                                                                    <optgroup label="Custom Filter">
                                                                        <option value="Group">Group</option>
                                                                        <option value="Tag">Tag</option>
                                                                        <option value="having">Having Contact</option>
                                                                    </optgroup>
                                                                    <optgroup label="Default Fields">
                                                                        {
                                                                            defaultFields && defaultFields.map((row, i) => {
                                                                                return <option key={row.key} value={`${row.key}`}>{row.label}</option>;
                                                                            })
                                                                        }
                                                                    </optgroup>
                                                                </select>
                                                            </Col>

                                                            :
                                                            currentPage == 'appointments' ?

                                                                <Col md="3">
                                                                    <select style={{ minHeight: 300 }} className="form-control valid" id="contact_fields" name="contact_fields[]" multiple="multiple" onChange={(e) => OptionChange()}>
                                                                        <optgroup label="Custom Filter">
                                                                            <option value="calendar">Calander</option>
                                                                            <option value="appointmentStatus">Status</option>
                                                                            <option value="added_by">Owner By</option>
                                                                        </optgroup>
                                                                    </select>
                                                                </Col>
                                                                :
                                                                currentPage == 'jobs' ?
                                                                    <Col md="3">
                                                                        <select style={{ minHeight: 300 }} className="form-control valid" id="contact_fields" name="contact_fields[]" multiple="multiple" onChange={(e) => OptionChange()}>
                                                                            <optgroup label="Custom Filter">
                                                                                <option value="Category">Categories</option>
                                                                                <option value="Service">Services</option>
                                                                                <option value="Tag">Tags</option>
                                                                            </optgroup>
                                                                            <optgroup label="Default Fields">
                                                                                {
                                                                                    defaultFields && defaultFields.map((row, i) => {
                                                                                        return <option key={row.key} value={`${row.key}`}>{row.label}</option>;
                                                                                    })
                                                                                }
                                                                            </optgroup>
                                                                        </select>
                                                                    </Col>

                                                                    :
                                                                    currentPage == 'payments' &&
                                                                    <Col md="3">
                                                                    </Col>

                                                    }
                                                    <Col md="9">
                                                        {createUI()}
                                                    </Col>
                                                    <Row className="g-1">
                                                        <label className="form-label">Choose Columns</label>
                                                        <Col md="12">
                                                            <Row>
                                                                <Col md="6">
                                                                    <label class="form-label">Available</label>
                                                                    <select style={{ minHeight: 150 }} value={""} className="form-control bg-white" multiple="multiple"
                                                                        onChange={(e) => { toggleColumnVisibility(e.target.value, true); }}
                                                                    >
                                                                        {
                                                                            userColumns && userColumns.map((row, i) => {
                                                                                return <option key={row.key} value={`${row.key}`}>{row.name}<Icon name="plus"></Icon></option>;
                                                                            })
                                                                        }
                                                                    </select>
                                                                </Col>

                                                                <Col md="6">
                                                                    <label class="form-label">Selected</label>
                                                                    {loading && (
                                                                        <select style={{ minHeight: 150 }}
                                                                            value={""}
                                                                            className="form-control bg-white"
                                                                            name="selected_columns[]" multiple="multiple"
                                                                            onChange={(e) => { toggleColumnVisibility(e.target.value, false); }}
                                                                        >
                                                                            {
                                                                                visibleColumns && visibleColumns.map((row, i) => {
                                                                                    return <option key={row.key} value={`${row.key}`}>{row.name}<Icon name="minus-sm"></Icon></option>;
                                                                                })
                                                                            }
                                                                        </select>
                                                                    )}
                                                                </Col>
                                                            </Row>
                                                        </Col>
                                                    </Row>
                                                    <Col className="col-12 text-end">
                                                        <Button type="submit" className="btn-md">Create</Button>
                                                    </Col>
                                                </Row>
                                            </div>
                                        </Form>
                                    </div>
                                </Card.Body>
                            </Card>
                        )
                    }
                </Block>
            </div>
        </Layout>
    )
}

export default FormCreate;