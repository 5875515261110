import { useEffect, useState } from 'react';
import Layout from '../../layout/default';
import { Card, Col, Form, Nav, Row, Tab, Spinner, Button, OverlayTrigger, Popover } from 'react-bootstrap';
import { Block, Icon } from '../../components';
import { sendRequest } from '../../utilities/api';
import { capitalizeFirstLetter } from '../../utilities';
import Swal from 'sweetalert2';
import { HostUrl } from '../../utilities/config';
function Email() {
    const [data, setData] = useState({});
    const [tab, setTab] = useState("sendgrid");
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        fetchMeta(tab);
    }, [tab]);

    const fetchMeta = (tab) => {
        setLoading(false);
        sendRequest(`email/setting/${tab}`, 'POST', {}).then(response => {
            if (response.status === true) {
                setLoading(true);
                setData(response.data.record);
            }
        })
            .catch(error => {
                console.error('POST request error:', error);
            });
    }

    const handleSubmit = async (event) => {
        event.preventDefault();
        const formData = new FormData(event.currentTarget);
        const submitData = new FormData();
        for (let [key, value] of formData.entries()) {
            submitData.append(key, value);
        }


        if (data.status != 'Current') {
            Swal.fire({
                title: 'Are you sure?',
                text: `You want to set ${capitalizeFirstLetter(tab)} as default service?`,
                icon: 'warning',
                showCancelButton: true,
                confirmButtonText: 'Yes, change it!'
            }).then((result) => {
                if (result.value) {
                    sendRequest(`email/setting/${tab}/update/${data.id}`, 'POST', submitData)
                        .then(response => {
                            if (response.status === true) {
                                Swal.fire(response.message)
                            }
                        })
                        .catch(error => {
                            console.error('POST request error:', error);
                        });

                }
            })
        } else {
            sendRequest(`email/setting/${tab}/update/${data.id}`, 'POST', submitData)
                .then(response => {
                    if (response.status === true) {
                        Swal.fire(response.message)
                    }
                })
                .catch(error => {
                    console.error('POST request error:', error);
                });
        }




    };

    return (
        <Layout title="Email Setting" content="container-fluid">
            <div className="emailSetting">
                <Block>
                    <Card>
                        <Card.Body>
                            <Tab.Container onSelect={(e) => setTab(e)} id="custom-tabs-example" defaultActiveKey="sendgrid">
                                <Nav variant="tabs" className="nav-tabs-s1 mb-3">
                                    <Nav.Item>
                                        <Nav.Link eventKey="sendgrid">Sendgrid</Nav.Link>
                                    </Nav.Item>
                                </Nav>

                                {
                                    !loading && (
                                        <div className="d-flex justify-content-center my-5">
                                            <Spinner animation="border" variant="secondary"></Spinner>
                                        </div>
                                    )
                                }
                                <Tab.Content>
                                    <Tab.Pane eventKey="sendgrid">
                                        <Row>
                                            <Col lg={6}>
                                                <Form onSubmit={handleSubmit}>
                                                    <Row className="g-3">
                                                        <Col lg={6}>
                                                            <Form.Group className="form-group">
                                                                <Form.Label>From Name</Form.Label>
                                                                <div className="form-control-wrap">
                                                                    <Form.Control defaultValue={data.from_name} type="text" name="from_name" placeholder="Name" required />
                                                                </div>
                                                            </Form.Group>
                                                        </Col>

                                                        <Col lg={6}>
                                                            <Form.Group className="form-group">
                                                                <Form.Label>From Email</Form.Label>
                                                                <div className="form-control-wrap">
                                                                    <Form.Control defaultValue={data.from_email} type="text" name="from_email" placeholder="Email" required />
                                                                </div>
                                                            </Form.Group>
                                                        </Col>

                                                        <Col lg={12}>
                                                            <Form.Group className="form-group">
                                                                <Form.Label>Api Key</Form.Label>
                                                                <div className="form-control-wrap">
                                                                    <Form.Control defaultValue={data.api_key} type="password" name="api_key" placeholder="***************" required />
                                                                </div>
                                                            </Form.Group>
                                                        </Col>

                                                        <Col lg={6}>
                                                            <Form.Group className="form-group">
                                                                <Form.Label>To Email</Form.Label>
                                                                <div className="form-control-wrap">
                                                                    <Form.Control defaultValue={data.to_email} type="text" name="to_email" placeholder="Email" required />
                                                                </div>
                                                            </Form.Group>
                                                        </Col>

                                                        {
                                                            data.status == 'Current' ? (
                                                                <Col className="col-12">
                                                                    <Button type="submit" className='btn-sm'>Update</Button>
                                                                </Col>
                                                            ) :
                                                                (
                                                                    <Col className="col-12">
                                                                        <Button variant={'warning'} type="submit" className='btn-sm'>Set as default service </Button>
                                                                    </Col>
                                                                )
                                                        }
                                                    </Row>
                                                </Form>
                                            </Col>
                                            <Col lg={6}>
                                                <button className="btn btn-color-primary btn-icon">
                                                    <Icon name="help"></Icon>
                                                    <span>Help</span>
                                                </button>

                                                <div className="">
                                                    <p className="mb-1">Signin to your <a target='_blank' href="https://app.sendgrid.com/login">account</a></p>
                                                    <p className="mb-1"><a target='_blank' href="https://app.sendgrid.com/settings/api_keys">Create API KEY</a>  and assign permissions</p>
                                                    <p className="mb-1">Don't forget to save your API key.</p>
                                                    <p className="mb-1">Add: <OverlayTrigger trigger="click" placement="top" overlay={
                                                        <Popover id="popover-positioned-top">
                                                            <Popover.Header as="h3">Webhook Url</Popover.Header>
                                                            <Popover.Body>
                                                                {`${HostUrl}/webhook/mail/statistics`}
                                                            </Popover.Body>
                                                        </Popover>
                                                    }>
                                                        <Button className='p-0' variant="link">This url </Button>
                                                    </OverlayTrigger> in your
                                                        <a target='_blank' href='https://app.sendgrid.com/settings/mail_settings'> SendGrid Account Webhook</a></p>
                                                </div>


                                            </Col>

                                        </Row>
                                    </Tab.Pane>
                                </Tab.Content>

                            </Tab.Container>
                        </Card.Body>
                    </Card>
                </Block>
            </div>
        </Layout>
    )
}

export default Email;
