import { useState, useEffect } from "react";
import { Link, useParams } from 'react-router-dom';
import { Card, Button, Row, Col, Form, Spinner, Tooltip, OverlayTrigger } from 'react-bootstrap';

import Layout from '../../layout/default';
import Block from '../../components/Block/Block';
import { Image, Icon, Media, Select, TimeZone } from '../../components';
import { getToday, today, toInitials } from "../../utilities";
import { sendRequest } from "../../utilities/api";
import { countries } from "../../store/customData";

function SequenceCreate(props) {
    const [meta, setMeta] = useState({ coachPartners: [] });
    const [loading, setLoading] = useState(false);
    const [modifyConstraint, setModifyConstraint] = useState(false);
    const [data, setData] = useState({});

    useEffect(() => {
        const fetchData = async () => {
            await fetchMeta();
        };
        fetchData();
    }, []);


    const fetchMeta = async () => {
        const formData = new FormData();
        await sendRequest('sequence/meta/fetch', 'POST', formData).then(response => {
            if (response.status === true) {
                setMeta(response.data);
                setData(response.data.constraintSettings)
                setLoading(true);
            }
        })
            .catch(error => {
                setLoading(true);
                console.error('POST request error:', error);
            });
    }


    const handleSubmit = async (event) => {
        event.preventDefault();
        const formData = new FormData(event.currentTarget);
        const submitData = new FormData();
        for (let [key, value] of formData.entries()) {
            submitData.append(key, value);
        }

        sendRequest('sequence/create', 'POST', submitData)
            .then(response => {
                if (response.status === true) {
                    props.navigate('/sequence-manage/lists');
                }
            })
            .catch(error => {
                console.error('POST request error:', error);
            });
    };
    return (
        <Layout title="Sequence" content="container-fluid">
            <div className="sequence-create">
                <Block.Head>
                    <Block.HeadContent>
                        <Block.Title tag="h2">Sequence List</Block.Title>
                        <nav>
                            <ol className="breadcrumb breadcrumb-arrow mb-0">
                                <li className="breadcrumb-item"><Link to="/">Home</Link></li>
                                <li className="breadcrumb-item"><Link to="/sequence-manage/lists">Sequence List</Link></li>
                                <li className="breadcrumb-item active" aria-current="page">Create</li>
                            </ol>
                        </nav>
                    </Block.HeadContent>
                </Block.Head>
                <Block>
                    <Card className="card-gutter-md">
                        <Card.Body>
                            <div className="bio-block">
                                {
                                    !loading ?
                                        <Spinner></Spinner>
                                        : (
                                            <Form onSubmit={handleSubmit}>
                                                <Row className="g-3">
                                                    <Col md="12">
                                                        <Form.Group className="form-group">
                                                            <Form.Label htmlFor="title">Title</Form.Label>
                                                            <div className="form-control-wrap">
                                                                <Form.Control type="text" name="title" placeholder="Title" required />
                                                            </div>
                                                        </Form.Group>
                                                    </Col>

                                                    <Col md="12">
                                                        <Form.Group className="form-group">
                                                            <Form.Label htmlFor="description">Description</Form.Label>
                                                            <div className="form-control-wrap">
                                                                <Form.Control as={'textarea'} name="description" placeholder="Description" required />
                                                            </div>
                                                        </Form.Group>
                                                    </Col>


                                                    <Col md="12">
                                                        <Form.Group className="form-group">
                                                            <Form.Label htmlFor="phone_number">Phone Number (Optional)</Form.Label>
                                                            <div className="form-control-wrap">
                                                                <Form.Select name="phone_number" className="form-controll">
                                                                    <option value="">Select</option>
                                                                    {
                                                                        meta?.phoneNumbers.map((row, i) => {
                                                                            return <option value={row.number}>{row.number}</option>
                                                                        })
                                                                    }
                                                                </Form.Select>
                                                            </div>
                                                        </Form.Group>
                                                    </Col>

                                                    <Col md="6">
                                                        <Form.Group className="form-group">
                                                            <Form.Label htmlFor="throttle_duration">Throttle duration</Form.Label>
                                                            <div className="form-control-wrap">
                                                                <Form.Select name="throttle_duration" className="form-controll">
                                                                    <option value="15">15 minutes </option>
                                                                    <option value="30">30 minutes</option>
                                                                    <option value="60">60 minutes</option>
                                                                </Form.Select>
                                                            </div>
                                                        </Form.Group>
                                                    </Col>

                                                    <Col md="6">
                                                        <Form.Group className="form-group">
                                                            <Form.Label htmlFor="throttle_value">Throttle value</Form.Label>
                                                            <div className="form-control-wrap">
                                                                <Form.Select name="throttle_value" className="form-controll">
                                                                    <option value="5">5</option>
                                                                    <option value="10">10</option>
                                                                    <option value="15">15</option>
                                                                    <option value="25">25</option>
                                                                    <option value="50">50</option>
                                                                    <option value="100">100</option>
                                                                    <option value="150">150</option>
                                                                    <option value="200">200</option>
                                                                    <option value="250">250</option>
                                                                    <option value="300">300</option>
                                                                    <option value="350">350</option>
                                                                    <option value="400">400</option>
                                                                    <option value="450">450</option>
                                                                    <option value="500">500</option>
                                                                </Form.Select>
                                                            </div>
                                                        </Form.Group>
                                                    </Col>



                                                    <Col md="12">
                                                        <Form.Check onChange={(e) => setModifyConstraint(e.target.checked)} type="checkbox" id="ModifyConstraintSettings" label="Modify Constraint Settings" />



                                                        <div className={modifyConstraint ? '' : 'd-none'}>
                                                            <fieldset>
                                                                <p>Current Date: {getToday()}:</p>
                                                                <div className="row g-3 time-constraint">
                                                                    <Col md="12">
                                                                        <Form.Group className="form-group">
                                                                            <Form.Label htmlFor="throttle_duration">Block Day</Form.Label>
                                                                            <div className="form-control-wrap">
                                                                                <Form.Control defaultValue={data.block_days} placeholder="For Example: 2022-04-16,2022-04-17" name="block_days" className="form-controll" />
                                                                                <div className="note">
                                                                                    Enter comma seperated dates i.e (yyyy-mm-dd,yyyy-mm-dd)
                                                                                </div>
                                                                            </div>
                                                                        </Form.Group>
                                                                    </Col>

                                                                    <Col md="6">
                                                                        <Form.Group className="form-group">
                                                                            <Form.Label htmlFor="from_time">From Time</Form.Label>
                                                                            <div className="form-control-wrap">
                                                                                <Form.Control defaultValue={data.from_time} type="time" name="from_time" className="form-controll" />

                                                                            </div>
                                                                        </Form.Group>
                                                                    </Col>

                                                                    <Col md="6">
                                                                        <Form.Group className="form-group">
                                                                            <Form.Label htmlFor="from_time">To Time</Form.Label>
                                                                            <div className="form-control-wrap">
                                                                                <Form.Control defaultValue={data.to_time} type="time" name="to_time" className="form-controll" />

                                                                            </div>
                                                                        </Form.Group>
                                                                    </Col>


                                                                    <Col md="6">
                                                                        <Form.Group className="form-group">
                                                                            <Form.Label htmlFor="skip_weak_days">Skip Week Days</Form.Label>
                                                                            <Form.Select
                                                                                className="select form-control"
                                                                                type="select"
                                                                                name="skip_weak_days[]"
                                                                                defaultValue={data.skip_weak_days}
                                                                                multiple
                                                                            >
                                                                                <option value="Monday">Monday</option>
                                                                                <option value="Tuesday">Tuesday</option>
                                                                                <option value="Wednesday">Wednesday</option>
                                                                                <option value="Thursday">Thursday</option>
                                                                                <option value="Friday">Friday</option>
                                                                                <option value="Saturday">Saturday</option>
                                                                                <option value="Sunday">Sunday</option>
                                                                            </Form.Select>
                                                                        </Form.Group>
                                                                    </Col>

                                                                    <Col md="6">
                                                                        <Form.Group className="form-group">
                                                                            <Form.Label htmlFor="holidays">USA Holidays</Form.Label>
                                                                            <Form.Select
                                                                                className="select form-control"
                                                                                type="select"
                                                                                name="holidays[]"
                                                                                defaultValue={data.holidays}
                                                                                multiple
                                                                            >
                                                                                {
                                                                                    meta.holidays && Object.entries(meta?.holidays).map(([key, value]) => {
                                                                                        return <option value={key}>{value}</option>
                                                                                    })

                                                                                }
                                                                            </Form.Select>
                                                                        </Form.Group>
                                                                    </Col>
                                                                </div>
                                                            </fieldset>
                                                        </div>
                                                    </Col>


                                                    <Col md="12">
                                                        <label>Stop on Response</label>
                                                        <br />
                                                        <Form.Check name="sms_received" type="checkbox" inline label="SMS Received" />
                                                        <Form.Check name="incoming_call" type="checkbox" inline label="Incoming Call" />
                                                        <Form.Check name="email_opened" type="checkbox" inline label="Email Opened" />

                                                    </Col>



                                                    <Form.Group className="form-group">
                                                        <Form.Label htmlFor="role">Assign To</Form.Label>
                                                        {meta?.coachPartners.length > 0 && (
                                                            <Select name="permissions[]" multiple removeItemButton>
                                                                <option value="">Select</option>
                                                                {
                                                                    meta?.coachPartners.map((row, i) => {
                                                                        return <option key={i} value={row.id}>{row.first_name} {row.last_name}</option>
                                                                    })
                                                                }
                                                            </Select>
                                                        )}
                                                    </Form.Group>

                                                    <Col className="col-12">
                                                        <Button className="btn-sm" type="submit">Create</Button>
                                                    </Col>
                                                </Row>
                                            </Form>

                                        )
                                }
                            </div>
                        </Card.Body>
                    </Card>
                </Block>
            </div>
        </Layout>
    )
}

export default SequenceCreate;