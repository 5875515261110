import { useState, useEffect, useRef } from "react";
import { Link, useParams } from 'react-router-dom';
import { Card, Button, Row, Col, Form, Spinner, Tab, Nav, Alert } from 'react-bootstrap';

import Layout from '../../layout/default';
import Block from '../../components/Block/Block';
import { Icon, Select, TimeZone } from '../../components';
import { uInfo, getToday } from "../../utilities";
import { sendRequest } from "../../utilities/api";
import { countries } from "../../store/customData";
import Swal from "sweetalert2";
import { GoogleOAuthProvider, GoogleLogin } from '@react-oauth/google';
import { jwtDecode } from "jwt-decode";

function ProfileSetting(props) {
    const { id } = useParams();
    const [data, setData] = useState({});
    const [loading, setLoading] = useState(true);
    const [tab, setTab] = useState("edit-profile");
    const [meta, setMeta] = useState({});
    const [isLoading, setIsLoading] = useState(false);
    const [connectedAccount, setConnectedAccount] = useState(null);

    useEffect(() => {
        const fetchData = async () => {
            await fetchMeta();
            await fetchRecordById();
        };
        fetchData();
    }, []);

    const fetchMeta = async () => {
        await sendRequest(`users/meta/fetch/${id}`, 'POST', []).then(response => {
            if (response.status === true) {
                setMeta(response.data);
            }
        })
            .catch(error => {
                console.error('POST request error:', error);
            });
    }


    const fetchRecordById = async (data) => {
        await sendRequest(`users/User/${id}`, 'POST').then(response => {
            if (response.status === true) {
                setData(response.data.list);
                setConnectedAccount(response.data.list.social_email);
            }
            setIsLoading(true);
        })
            .catch(error => {
                setIsLoading(true);
                console.error('POST request error:', error);
            });
    }




    const submitProfile = async (event) => {
        setLoading(false);

        event.preventDefault();
        const formData = new FormData(event.currentTarget);
        const submitData = new FormData();
        for (let [key, value] of formData.entries()) {
            submitData.append(key, value);
        }

        await sendRequest(`users/${data.type}/update/${id}`, 'POST', submitData).then(response => {
            if (response.status === true) {
                Swal.fire(response.message)
            }
            setLoading(true);
        })
            .catch(error => {
                setLoading(true);
                console.error('POST request error:', error);
            });
    }

    const updateUserPhoneSetting = async (event) => {
        setLoading(false);
        event.preventDefault();
        const formData = new FormData(event.currentTarget);
        const submitData = new FormData();
        for (let [key, value] of formData.entries()) {
            submitData.append(key, value);
        }

        await sendRequest(`users/update/phone/setting/${id}`, 'POST', submitData).then(response => {
            if (response.status === true) {
                Swal.fire(response.message)
            }
            setLoading(true);
        })
            .catch(error => {
                setLoading(true);
                console.error('POST request error:', error);
            });
    }


    const submitChangePassword = async (event) => {
        setLoading(false);
        event.preventDefault();
        const formData = new FormData(event.currentTarget);
        const submitData = new FormData();
        for (let [key, value] of formData.entries()) {
            submitData.append(key, value);
        }

        await sendRequest(`users/managers/update/password/${id}`, 'POST', submitData).then(response => {
            if (response.status === true) {
                Swal.fire(response.message)
            }
            setLoading(true);
        })
            .catch(error => {
                setLoading(true);
                console.error('POST request error:', error);
            });
    }

    const handleChangeBizHours = async (event) => {
        setLoading(false);
        event.preventDefault();
        const formData = new FormData(event.currentTarget);
        const submitData = new FormData();
        for (let [key, value] of formData.entries()) {
            submitData.append(key, value);
        }

        await sendRequest(`users/managers/update/bizhours/${id}`, 'POST', submitData).then(response => {
            if (response.status === true) {
                Swal.fire(response.message)
            }
            setLoading(true);
        })
            .catch(error => {
                setLoading(true);
                console.error('POST request error:', error);
            });
    }

    const toggle = async (tab) => {
        setTab(tab);
    }

    const disconnectAccount = async () => {
        Swal.fire({
            title: 'Are you sure?',
            html: `You want to disconnect <b>${connectedAccount}</b> account?`,
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: 'Yes, do it!'
        }).then((result) => {
            if (result.value) {
                sendRequest(`disconnect-social-account`, 'POST', []).then(response => {
                    if (response.status === true) {
                        Swal.fire(response.message)
                    }
                    setLoading(true);
                    setConnectedAccount(null);
                })
                    .catch(error => {
                        setLoading(true);
                        console.error('POST request error:', error);
                    });
            }
        });
    }

    return (
        <Layout title="Profile" content="container-fluid">
            <div className="main-custom-field">
                <Block.Head>
                    <Block.HeadContent>
                        <Block.Title tag="h2">Profile</Block.Title>
                        <nav>
                            <ol className="breadcrumb breadcrumb-arrow mb-0">
                                <li className="breadcrumb-item"><Link to="/">Home</Link></li>
                                <li className="breadcrumb-item active" aria-current="page">Profile</li>
                            </ol>
                        </nav>
                    </Block.HeadContent>
                </Block.Head>
                <Block>
                    {
                        !isLoading ? (
                            <Spinner animation="grow" variant="primary"></Spinner>
                        )
                            : (
                                <Card className="card-gutter-md">
                                    <Card.Body>

                                        <Tab.Container onSelect={(e) => toggle(e)} id="custom-tabs-example" defaultActiveKey={tab}>
                                            <Nav variant="tabs" className="nav-tabs-s1 mb-3">
                                                <Nav.Item>
                                                    <Nav.Link eventKey="edit-profile">Edit Profile</Nav.Link>
                                                </Nav.Item>
                                                {
                                                    (uInfo().type == 'Manager' || uInfo().type == 'SubManager') && (
                                                        <Nav.Item>
                                                            <Nav.Link eventKey="personal-phone">Personal Phone Settings</Nav.Link>
                                                        </Nav.Item>
                                                    )
                                                }
                                                {/* <Nav.Item>
                                                <Nav.Link eventKey="delete-number">Deleted PhoneNumber(s)</Nav.Link>
                                            </Nav.Item> */}
                                                <Nav.Item>
                                                    <Nav.Link eventKey="change-password">Change Password</Nav.Link>
                                                </Nav.Item>
                                                <Nav.Item>
                                                    <Nav.Link eventKey="biz-hours">Biz Hours</Nav.Link>
                                                </Nav.Item>
                                                <Nav.Item>
                                                    <Nav.Link eventKey="connect-google">Connect Google</Nav.Link>
                                                </Nav.Item>
                                            </Nav>
                                            <Tab.Content>
                                                <Tab.Pane eventKey="edit-profile">
                                                    <div className="bio-block">
                                                        <Form onSubmit={submitProfile}>
                                                            <Row className="g-3 mb-3">
                                                                <Col md="6">
                                                                    <Form.Group className="form-group">
                                                                        <Form.Label htmlFor="first_name">First Name</Form.Label>
                                                                        <div className="form-control-wrap">
                                                                            <Form.Control defaultValue={data.first_name} type="text" name="first_name" placeholder="First Name" required />
                                                                        </div>
                                                                    </Form.Group>
                                                                </Col>
                                                                <Col md="6">
                                                                    <Form.Group className="form-group">
                                                                        <Form.Label htmlFor="last_name">Last Name</Form.Label>
                                                                        <div className="form-control-wrap">
                                                                            <Form.Control defaultValue={data.last_name} type="text" name="last_name" placeholder="Last Name" required />
                                                                        </div>
                                                                    </Form.Group>
                                                                </Col>

                                                                <Col md="6">
                                                                    <Form.Group className="form-group">
                                                                        <Form.Label htmlFor="email">Email</Form.Label>
                                                                        <div className="form-control-wrap">
                                                                            <Form.Control defaultValue={data.email} type="email" name="email" placeholder="Email" required />
                                                                        </div>
                                                                    </Form.Group>
                                                                </Col>

                                                                <Col md="6">
                                                                    <Form.Group className="form-group">
                                                                        <Form.Label htmlFor="country_code">Country Code</Form.Label>
                                                                        <div className="form-control-wrap">
                                                                            <Select defaultValue={data.country_code} name="country_code">
                                                                                <option value="">Select Country</option>
                                                                                {
                                                                                    countries.map((row, i) => {
                                                                                        return <option key={i} value={row.code}>{row.label}</option>
                                                                                    })
                                                                                }
                                                                            </Select>
                                                                        </div>
                                                                    </Form.Group>
                                                                </Col>


                                                                <Col md="6">
                                                                    <Form.Group className="form-group">
                                                                        <Form.Label htmlFor="phone">Phone</Form.Label>
                                                                        <div className="form-control-wrap">
                                                                            <Form.Control defaultValue={data.phone} type="number" name="phone" placeholder="Phone" required />
                                                                        </div>
                                                                    </Form.Group>
                                                                </Col>

                                                                <Col>
                                                                    <TimeZone main_key="main_zone" sub_key={"timezone"} mainZone={data.main_zone} zone={data.time_zone} />
                                                                </Col>


                                                                <Col md="6">
                                                                    <Form.Group className="form-group">
                                                                        <Form.Label htmlFor="city">City</Form.Label>
                                                                        <div className="form-control-wrap">
                                                                            <Form.Control defaultValue={data.city} type="text" name="city" placeholder="City" required />
                                                                        </div>
                                                                    </Form.Group>
                                                                </Col>

                                                                <Col md="6">
                                                                    <Form.Group className="form-group">
                                                                        <Form.Label htmlFor="state">State</Form.Label>
                                                                        <div className="form-control-wrap">
                                                                            <Form.Control defaultValue={data.state} type="text" name="state" placeholder="State" required />
                                                                        </div>
                                                                    </Form.Group>
                                                                </Col>

                                                                <Col md="6">
                                                                    <Form.Group className="form-group">
                                                                        <Form.Label htmlFor="zip">Zip</Form.Label>
                                                                        <div className="form-control-wrap">
                                                                            <Form.Control defaultValue={data.zip} type="text" name="zip" placeholder="Zip" required />
                                                                        </div>
                                                                    </Form.Group>
                                                                </Col>

                                                                <Col md="6">
                                                                    <Form.Group className="form-group">
                                                                        <Form.Label htmlFor="company_name">Company Name</Form.Label>
                                                                        <div className="form-control-wrap">
                                                                            <Form.Control defaultValue={data.company_name} type="text" name="company_name" placeholder="Company Name" required />
                                                                        </div>
                                                                    </Form.Group>
                                                                </Col>

                                                                <Col md="12">
                                                                    <Form.Group className="form-group">
                                                                        <Form.Label htmlFor="address">Address</Form.Label>
                                                                        <div className="form-control-wrap">
                                                                            <Form.Control as={'textarea'} defaultValue={data.address} name="address" placeholder="Address" required />
                                                                        </div>
                                                                    </Form.Group>
                                                                </Col>


                                                                <Col className="col-12">
                                                                    <Button disabled={loading ? false : true} type="submit" className="btn-sm">Submit</Button>
                                                                </Col>
                                                            </Row>
                                                        </Form>
                                                    </div>
                                                </Tab.Pane>
                                                <Tab.Pane eventKey="personal-phone">
                                                    <div className="bio-block">
                                                        <Form onSubmit={updateUserPhoneSetting}>
                                                            <Row className="g-3 mb-3">
                                                                <Col lg="6">
                                                                    <Form.Label htmlFor="title">Direct number​​</Form.Label>
                                                                    <Form.Select
                                                                        name="direct_number"
                                                                        defaultValue={data.direct_number}
                                                                        required
                                                                    >
                                                                        <option value="">Select Number</option>
                                                                        {
                                                                            meta.numbers && meta.numbers.map((row, i) => {
                                                                                return (
                                                                                    (row.is_locked == 'No' || row.number == data.direct_number) && (
                                                                                        <option key={i} value={row.number}>{row.number}</option>
                                                                                    )
                                                                                )
                                                                            })
                                                                        }

                                                                    </Form.Select>
                                                                </Col>

                                                                <Col lg="6">
                                                                    <Form.Label htmlFor="title">Default outbound phone</Form.Label>
                                                                    <Form.Select

                                                                        name="outbound_phone"
                                                                        defaultValue={data.outbound_phone}
                                                                        required
                                                                    >
                                                                        <option value="">Select Number</option>
                                                                        {
                                                                            meta.numbers && meta.numbers.map((row, i) => {
                                                                                return (
                                                                                    <option key={i} value={row.number}>{row.number}</option>
                                                                                )
                                                                            })
                                                                        }
                                                                    </Form.Select>
                                                                </Col>

                                                                <Col lg="6">
                                                                    <Form.Label htmlFor="title">Inbound Call Option</Form.Label>
                                                                    <Form.Select

                                                                        name="inbound_call_option"
                                                                        defaultValue={data.inbound_call_option}
                                                                    // required
                                                                    >
                                                                        {/* <option value="0">None</option>
                                                                        <option value="1">Ring browser only</option> */}
                                                                        <option value="2">Ring Phone only</option>
                                                                        {/* <option value="5">Ring Phone only (Whisper)</option> */}
                                                                        {/* <option value="3">Ring browser and forward calls simultaneously ** Voicemail disable</option> */}
                                                                        {/* <option value="6">Ring browser and forward calls simultaneously (Whisper) ** Voicemail disable</option> */}

                                                                    </Form.Select>
                                                                </Col>

                                                                <Col lg="6">

                                                                    <Form.Group className="form-group">
                                                                        <Form.Label htmlFor="title">Forward number</Form.Label>
                                                                        <div className="form-control-wrap">
                                                                            <Form.Control defaultValue={data.forward_number} type="text" name="forward_number" placeholder="Forward Number" required />
                                                                        </div>
                                                                    </Form.Group>
                                                                </Col>
                                                                <Col className="col-12">
                                                                    <Button disabled={loading ? false : true} type="submit" className="btn-sm">Update Setting</Button>
                                                                </Col>
                                                            </Row>
                                                        </Form>

                                                    </div>
                                                </Tab.Pane>

                                                <Tab.Pane eventKey="change-password">
                                                    <div className="bio-block">
                                                        <Form onSubmit={submitChangePassword}>
                                                            <Row className="g-3 mb-3">
                                                                <Col lg="12">
                                                                    <Form.Group className="form-group">
                                                                        <Form.Label htmlFor="title">Current Password</Form.Label>
                                                                        <div className="form-control-wrap">
                                                                            <Form.Control type="password" name="current_password" placeholder="Current Password" required />
                                                                        </div>
                                                                    </Form.Group>
                                                                </Col>

                                                                <Col lg="12">
                                                                    <Form.Group className="form-group">
                                                                        <Form.Label htmlFor="title">New Password</Form.Label>
                                                                        <div className="form-control-wrap">
                                                                            <Form.Control type="password" name="new_password" placeholder="New Password" required />
                                                                        </div>
                                                                    </Form.Group>
                                                                </Col>

                                                                <Col lg="12">
                                                                    <Form.Group className="form-group">
                                                                        <Form.Label htmlFor="title">Confirm Password</Form.Label>
                                                                        <div className="form-control-wrap">
                                                                            <Form.Control type="password" name="new_confirm_password" placeholder="Confirm Password" required />
                                                                        </div>
                                                                    </Form.Group>
                                                                </Col>
                                                                <Col className="col-12">
                                                                    <Button disabled={loading ? false : true} type="submit" className="btn-sm">Submit</Button>
                                                                </Col>
                                                            </Row>
                                                        </Form>

                                                    </div>
                                                </Tab.Pane>

                                                <Tab.Pane eventKey="biz-hours">
                                                    <div className="bio-block">
                                                        <Form onSubmit={handleChangeBizHours}>
                                                            <Row className="g-3">
                                                                <fieldset>
                                                                    <p>Current Date: {getToday()}:</p>
                                                                    <div className="row g-3 time-constraint">
                                                                        <Col md="12">
                                                                            <Form.Group className="form-group">
                                                                                <Form.Label htmlFor="throttle_duration">Block Day</Form.Label>
                                                                                <div className="form-control-wrap">
                                                                                    <Form.Control defaultValue={data.constraint_settings.block_days} placeholder="For Example: 2022-04-16,2022-04-17" name="block_days" className="form-controll" />
                                                                                    <div className="note">
                                                                                        Enter comma seperated dates i.e (yyyy-mm-dd,yyyy-mm-dd)
                                                                                    </div>
                                                                                </div>
                                                                            </Form.Group>
                                                                        </Col>
                                                                        <Form.Control defaultValue={2} type="hidden" name="step" required />

                                                                        <Col md="6">
                                                                            <Form.Group className="form-group">
                                                                                <Form.Label htmlFor="from_time">From Time</Form.Label>
                                                                                <div className="form-control-wrap">
                                                                                    <Form.Control defaultValue={data.constraint_settings.from_time} type="time" name="from_time" className="form-controll" />

                                                                                </div>
                                                                            </Form.Group>
                                                                        </Col>

                                                                        <Col md="6">
                                                                            <Form.Group className="form-group">
                                                                                <Form.Label htmlFor="from_time">To Time</Form.Label>
                                                                                <div className="form-control-wrap">
                                                                                    <Form.Control defaultValue={data.constraint_settings.to_time} type="time" name="to_time" className="form-controll" />

                                                                                </div>
                                                                            </Form.Group>
                                                                        </Col>


                                                                        <Col md="6">
                                                                            <Form.Group className="form-group">
                                                                                <Form.Label htmlFor="skip_weak_days">Skip Week Days</Form.Label>
                                                                                <Form.Select
                                                                                    className="select form-control"
                                                                                    type="select"
                                                                                    name="skip_weak_days[]"
                                                                                    defaultValue={data.constraint_settings.skip_weak_days}
                                                                                    multiple
                                                                                >
                                                                                    <option value="Monday">Monday</option>
                                                                                    <option value="Tuesday">Tuesday</option>
                                                                                    <option value="Wednesday">Wednesday</option>
                                                                                    <option value="Thursday">Thursday</option>
                                                                                    <option value="Friday">Friday</option>
                                                                                    <option value="Saturday">Saturday</option>
                                                                                    <option value="Sunday">Sunday</option>
                                                                                </Form.Select>
                                                                            </Form.Group>
                                                                        </Col>

                                                                        <Col md="6">
                                                                            <Form.Group className="form-group">
                                                                                <Form.Label htmlFor="holidays">USA Holidays</Form.Label>
                                                                                <Form.Select
                                                                                    className="select form-control"
                                                                                    type="select"
                                                                                    name="holidays[]"
                                                                                    defaultValue={data.constraint_settings.holidays}
                                                                                    multiple
                                                                                >
                                                                                    {
                                                                                        meta.holidays && Object.entries(meta?.holidays).map(([key, value]) => {
                                                                                            return <option value={key}>{value}</option>
                                                                                        })

                                                                                    }
                                                                                </Form.Select>
                                                                            </Form.Group>
                                                                        </Col>
                                                                    </div>
                                                                </fieldset>
                                                                <Col className="col-12">
                                                                    <Button type="submit" variant="primary"
                                                                        disabled={loading ? false : true}
                                                                    ><span className="ms-1">Update</span>
                                                                    </Button>
                                                                </Col>
                                                            </Row>
                                                        </Form>
                                                    </div>
                                                </Tab.Pane>

                                                <Tab.Pane eventKey="connect-google">
                                                    <Alert variant="primary">
                                                        To make logging in easier and more secure, you can now connect your Google account. By doing so, you'll be able to log in quickly and effortlessly using your Google credentials.
                                                    </Alert>
                                                    <Col className="col-3 mt-4">
                                                        <div className="bio-block">
                                                            {
                                                                connectedAccount ?
                                                                    (
                                                                        <>
                                                                            <h6>Connected Account</h6>
                                                                            <div className="d-flex justify-content-between align-items-center border p-3 mb-2 bg-white rounded shadow-sm">
                                                                                <span>{connectedAccount}</span>
                                                                                <button className="btn btn-link text-danger" onClick={disconnectAccount}>
                                                                                    <Icon name="trash" className='text-danger' ></Icon>
                                                                                </button>
                                                                            </div>
                                                                        </>
                                                                        // <Alert variant="secondary">Account connected</Alert>
                                                                    ) : (
                                                                        <GoogleOAuthProvider clientId="705520943486-bgnmd05mbod44u56k9qf97c6b0474v0s.apps.googleusercontent.com">
                                                                            <GoogleLogin
                                                                                onSuccess={credentialResponse => {

                                                                                    const userData = jwtDecode(credentialResponse.credential);

                                                                                    const submitData = new FormData();

                                                                                    submitData.append('social_email', userData.email);
                                                                                    submitData.append('aud', userData.aud);
                                                                                    submitData.append('social_id', btoa(userData.sub.toString()));
                                                                                    submitData.append('social_type', 'google');

                                                                                    sendRequest('connect-social-account', 'POST', submitData)
                                                                                        .then(response => {
                                                                                            if (response.status === true) {
                                                                                                Swal.fire('Account connected successfully!')
                                                                                                setConnectedAccount(response.data.social_email);
                                                                                            }
                                                                                        })
                                                                                        .catch(error => {
                                                                                            console.error('POST request error:', error);
                                                                                        });
                                                                                }}
                                                                                onError={() => {
                                                                                    console.log('Login Failed');
                                                                                }}
                                                                            />
                                                                        </GoogleOAuthProvider>
                                                                    )
                                                            }


                                                        </div>
                                                    </Col>
                                                </Tab.Pane>
                                            </Tab.Content>
                                        </Tab.Container>
                                    </Card.Body>
                                </Card>
                            )
                    }

                </Block>
            </div>
        </Layout >
    )
}

export default ProfileSetting;